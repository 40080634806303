import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { classNames } from '../../utils/conditionalClasses';

const categories = [
  { id: 1, name: 'T-shirt', imgPath: '/images/marketplace/t-shirt.png' },
  { id: 2, name: 'Sweatshirt', imgPath: '/images/marketplace/sweatshirt.png' },
  { id: 3, name: 'Hoodie', imgPath: '/images/marketplace/hoodie.png' },
  { id: 4, name: 'Halı', imgPath: '/images/marketplace/hali.png' },
  { id: 5, name: 'Kolye', imgPath: '/images/marketplace/kolye.png' },
  { id: 6, name: 'Küpe', imgPath: '/images/marketplace/kupe.png' },
  { id: 7, name: 'Bileklik', imgPath: '/images/marketplace/bileklik.png' },
  { id: 8, name: 'Yüzük', imgPath: '/images/marketplace/yuzuk.png' },
  { id: 9, name: 'Halhal', imgPath: '/images/marketplace/halhal.webp' },
  { id: 10, name: 'Deri', imgPath: '/images/marketplace/deri.png' },
  {
    id: 11,
    name: 'Metal',
    imgPath: '/images/marketplace/duvarDekoru.png',
  },
  { id: 12, name: 'Ahşap', imgPath: '/images/marketplace/ahsap.png' },
  { id: 13, name: 'Havlu', imgPath: '/images/marketplace/havlu.png' },
  { id: 14, name: 'Kanvas', imgPath: '/images/marketplace/kanvas.png' },
  { id: 15, name: 'Çanta', imgPath: '/images/marketplace/canta.png' },
  { id: 16, name: 'Önlük', imgPath: '/images/marketplace/onluk.png' },
  { id: 17, name: 'Şapka', imgPath: '/images/marketplace/sapka.png' },
  { id: 18, name: 'Akrilik', imgPath: '/images/marketplace/epoksi.png' },
  { id: 19, name: 'Bardak', imgPath: '/images/marketplace/bardak.png' },
  { id: 20, name: 'Terlik', imgPath: '/images/marketplace/terlik.png' },
  { id: 21, name: 'Evlilik', imgPath: '/images/marketplace/pijama.png' },
  {
    id: 22,
    name: 'Kedi-Köpek',
    imgPath: '/images/marketplace/evcil-hayvan.png',
  },
  {
    id: 25,
    name: 'Tumbler',
    imgPath: '/images/marketplace/tumbler.webp',
  },
  {
    id: 24,
    name: 'Ornament',
    imgPath: '/images/marketplace/ornament.webp',
  },
];
interface TopCategoryLineProps {
  handleCategoryId: (id: number, name: string) => void;
  categoryName: string | undefined;
}
const TopCategoryLine: React.FC<TopCategoryLineProps> = ({
  handleCategoryId,
  categoryName,
}) => {
  return (
    <div className="flow-root">
      <div className="relative -mx-2 -my-4 scrollbar-hide">
        <div className="inline-block min-w-full align-middle sm:px-6">
          <div className="w-full my-10 overflow-hidden">
            <div className="grid grid-cols-4 sm:grid-cols-9 xl:grid-cols-12 touch-pan-x py-3 gap-2">
              {categories?.map((category: any) => (
                <div
                  onClick={() => {
                    handleCategoryId(category.id, category.name);
                  }}
                  className={classNames(
                    categoryName === category.name
                      ? 'border-b-2 font-bold text-sm md:text-md border-headerPrimary text-[#1F2937]'
                      : 'text-[#374151] text-xs',
                    'col-span-1 whitespace-nowrap font-medium min-[1306px]:font-medium text-center px-3 first:pl-0 last:pr-0 cursor-pointer flex flex-col items-center hover:text-headerPrimary'
                  )}
                  key={category.id}
                >
                  <img
                    src={category.imgPath}
                    alt={category.name}
                    className="w-10 h-10 mx-auto mb-1 rounded-md"
                  />
                  {category.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCategoryLine;
