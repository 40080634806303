import React from 'react';
import API_URL from '../../../config';
import { classNames } from '../../../utils/conditionalClasses';
import { CloseCircle } from 'iconsax-react';

interface Props {
  imgUrl: string;
  title: string;
  sku: string;
  index: number;
  selectedIndex?: number | null;
  setSelectedOrderItem: React.Dispatch<React.SetStateAction<null>>;
}
const MatchedProductModal: React.FC<Props> = ({
  imgUrl,
  title,
  sku,
  selectedIndex,
  setSelectedOrderItem,
  index,
}) => {
  return (
    <div
      className={classNames(
        selectedIndex === index ? ' block' : 'hidden',
        'absolute top-0 -right-60 space-x-2 flex items-center bg-lightGray rounded-lg p-2 z-10'
      )}
    >
      <img
        src={
          'https://api.rexven.com/images/marketplace/supplier-images/' + imgUrl
        }
        alt="product_image"
        className="w-32 h-32 rounded-xl"
      />
      <div className="text-sm relative">
        <CloseCircle
          size={24}
          color="black"
          className="absolute -top-6 right-0 cursor-pointer"
          onClick={() => setSelectedOrderItem(null)}
        />
        <p>{title}</p>
        <p>SKU:{sku}</p>
      </div>
    </div>
  );
};

export default MatchedProductModal;
