import { Dialog, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import alertNotification from '../../utils/alertNotification';
import DropImageResend from '../tools/common/DropImageResend';
import API_URL from '../../config';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  orderItems: any;
  getOrders: () => void;
}
const ResendModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  orderItems,
  getOrders,
}) => {
  const [image, setImage] = useState<any>(null);
  const [description, setDescription] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [validationError, setValidationError] = useState({
    subject: '',
    description: '',
    image: '',
  });

  const handleSubjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSubject(e.target.value);
  };
  let http = axios.create({
    headers: {
      'Content-type': 'multipart/form-data',
    },
    baseURL: API_URL,
    withCredentials: true,
  });
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
  };
  const validate = () => {
    if (subject === '') {
      setValidationError((prev) => ({ ...prev, subject: 'Konu seçiniz' }));
      return false;
    }
    if (subject === 'faultyManufacture') {
      if (description === '') {
        setValidationError((prev) => ({
          ...prev,
          description: 'Açıklama giriniz',
        }));
        return false;
      }
      if (!image) {
        setValidationError((prev) => ({
          ...prev,
          image: 'Görsel yükleyiniz',
        }));
        return false;
      }
    }
    return true;
  };

  const clearValidation = () => {
    setValidationError({
      subject: '',
      description: '',
      image: '',
    });
    setDescription('');
    setSubject('');
    setImage(null);
  };
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const isValid = validate();
    const formData = new FormData();
    formData.append('description', description);
    formData.append('subject', subject);
    if (Array.isArray(image)) {
      image.forEach((img) =>
        formData.append('resendRequestImage', img, `resend_${uuidv4()}`)
      );
    } else {
      formData.append('resendRequestImage', image);
    }
    formData.append('orderItemId', orderItems.id);

    try {
      if (isValid) {
        await http.post(
          `api/v1/marketplace/seller/order-send-again-requests`,
          formData
        );
        alertNotification('success', 'Talep başarıyla oluşturuldu');
        clearValidation();
        setIsModalOpen(false);
        getOrders();
      }
    } catch (error: any) {
      alertNotification('error', error.response.data);
    }
  };

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          clearValidation();
          setIsModalOpen(false);
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-center py-4 leading-6 text-gray-900"
                >
                  Siparişi tekrar gönderme talebi
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-xs text-gray-500">
                    {
                      ' Siparişinizde hata olması durumunda iligli ürünü tekrar göndermek için talep oluşturabilirsiniz. '
                    }
                  </p>
                  {
                    <>
                      <div className="flex justify-center items-center flex-col">
                        {
                          <div className="flex items-center justify-start space-x-4 my-2">
                            <div className="flex items-center">
                              <img
                                src={orderItems?.seller_product.images[0]}
                                alt=""
                                className="w-12 h-12 object-cover"
                              />
                              <div className="ml-2">
                                <h3 className="text-sm font-semibold">
                                  {Object.keys(
                                    orderItems?.seller_product?.attributes[0]
                                  ).map((key) => {
                                    return (
                                      <p>
                                        {key +
                                          ':' +
                                          orderItems?.seller_product
                                            ?.attributes[0][key]}
                                      </p>
                                    );
                                  })}
                                </h3>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div className="w-full flex justify-center space-x-2">
                        <form
                          action=""
                          className="flex flex-col space-y-2"
                          onSubmit={handleSubmit}
                        >
                          <div>
                            <label htmlFor="subject">Konu</label>
                            <select
                              name="subject"
                              id="subject"
                              onChange={handleSubjectChange}
                              className="border-[#6B7280] rounded-md text-sm w-full"
                            >
                              <option value="">Konu seçiniz</option>
                              <option value="faultyManufacture">
                                Üretici Hatası
                              </option>
                              <option value="other">
                                Üretici Hatası Değil
                              </option>
                            </select>
                            <p className="text-red-500 text-xs mt-2">
                              {validationError.subject}{' '}
                            </p>
                          </div>
                          <div>
                            <label htmlFor="description">Açıklama</label>
                            <textarea
                              name=""
                              onChange={handleDescriptionChange}
                              id=""
                              placeholder="Açıklama giriniz.."
                              className="w-full h-40 rounded-lg text-sm"
                            ></textarea>
                            <p className="text-red-500 text-xs mt-2">
                              {validationError.description}{' '}
                            </p>
                          </div>
                          <div>
                            <label htmlFor="">Görsel</label>
                            <div className="flex space-x-2 items-center">
                              <DropImageResend setImage={setImage} />
                              {image?.preview && (
                                <div className="flex space-x-2">
                                  {image.preview.map((img: any) => {
                                    return (
                                      <img
                                        src={img}
                                        alt=""
                                        className="w-20 h-20 object-cover"
                                      />
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                            <p className="text-red-500 text-xs mt-2">
                              {validationError.image}{' '}
                            </p>
                          </div>
                          <div className="flex justify-between space-x-2">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setIsModalOpen(false);
                              }}
                              className="bg-blue-500 text-white rounded-lg px-2 py-2 text-sm w-32"
                            >
                              Vazgeç
                            </button>
                            <button
                              type="submit"
                              className="bg-headingPrimary text-white rounded-lg text-sm px-2 py-2 w-32"
                              onClick={handleSubmit}
                            >
                              Talep oluştur
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ResendModal;
