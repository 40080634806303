import React, { useEffect } from 'react';
import LoadingComponent from '../common/LoadingComponent';
import { Edit2, Link1 } from 'iconsax-react';
import OrderMatchingModal from '../marketplaceOrders/OrderMatchingModal';
import httpClient from '../../utils/httpClient';
import alertNotification from '../../utils/alertNotification';

const tableHeader = [
  { name: 'Varyasyonlar' },
  { name: 'Özellik' },
  { name: 'Eşleşen Ürün' },
  { name: 'Kişiselleştirme' },
  { name: 'İşlem' },
];
interface Props {
  data: any;
  isLoading: boolean;
  refetch: () => void;
}

const ProductVariationsTable: React.FC<Props> = ({
  isLoading,
  data,
  refetch,
}) => {
  const [selectedOrder, setSelectedOrder] = React.useState<any>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [localData, setLocalData] = React.useState<any[]>(
    data?.data?.sellerProductVariants
  );

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    sellerProductId: number
  ) => {
    const isChecked = event.target.checked;

    // UI anında güncellensin
    setLocalData((prevData) =>
      prevData.map((prod) =>
        +prod.id === sellerProductId
          ? { ...prod, isImageSaved: isChecked }
          : prod
      )
    );

    try {
      // Sunucuya isteği gönder
      await httpClient.patch('/api/v1/marketplace/seller/isImageSaved', {
        sellerProductId,
        isImageSaved: isChecked,
      });
    } catch (error) {
      console.error('Error updating isImageSaved:', error);

      // Hata durumunda UI'yı eski haline getir
      setLocalData((prevData) =>
        prevData.map((prod) =>
          +prod.id === sellerProductId
            ? { ...prod, isImageSaved: !isChecked }
            : prod
        )
      );
    }
  };
  const handleProductMatch = (prod: any) => {
    setSelectedOrder({
      id: prod.id,

      seller_order_items: [
        {
          id: prod.seller_order_item_id,
          seller_product: {
            id: prod.id,
            title: prod.title,
            images: prod.images,
            attributes: [prod.attributes[0]],
          },
          isRematch: prod.supplier_product_variant_id ? true : false,
        },
      ],
    });
    setIsModalOpen(true);
  };

  useEffect(() => {
    setLocalData(data?.data?.sellerProductVariants);
  }, [data]);
  return (
    <div className="flow-root">
      <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
        <div className="inline-block min-w-full py-2 align-middle px-4">
          <div className=" flex flex-col flex-shrink-0 bg-[#F9FAFB] rounded-xl mt-8">
            <div className="relative overflow-y-auto overflow-x-auto scrollbar-hide">
              <div className="inline-block min-w-full align-middle h-[100vh]">
                <table className="w-full">
                  <thead>
                    <tr>
                      {tableHeader.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className="sticky top-0 z-10 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                            // scope="col"
                          >
                            {' '}
                            <p className="text-xs md:text-sm">{item.name}</p>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  {isLoading ? (
                    <div className="w-full flex justify-center">
                      <LoadingComponent />
                    </div>
                  ) : (
                    <tbody className="divide-y divide-gray-200 bg-[#F9FAFB]">
                      {/* <tr className="h-10"></tr> */}
                      {localData?.map((product: any, idx: number) => {
                        const localProduct =
                          localData &&
                          localData?.find((item) => item.id === product.id);
                        return (
                          <tr key={idx} className="text-[#374151]">
                            <td className="px-1 text-xs md:text-sm py-2">
                              <div className="flex justify-center items-center">
                                <img
                                  src={product.images[0]}
                                  alt="product_etsy"
                                  className="w-[80px] aspect-square rounded-xl"
                                />
                                <p className="pl-2 pr-8 w-[450px]">
                                  {product.title}
                                </p>
                              </div>
                            </td>
                            <td className="px-1 text-xs md:text-sm">
                              <div className="flex flex-col justify-center w-60">
                                {Object.entries(product.attributes[0]).map(
                                  ([key, value]) => (
                                    <div
                                      className="flex space-x-1 items-center"
                                      key={`${key}`}
                                    >
                                      <p className="text-[#1F2937] font-medium">
                                        {key}:
                                      </p>
                                      <p>{value as string}</p>
                                    </div>
                                  )
                                )}
                              </div>
                            </td>

                            <td className="text-xs md:text-sm text-[#374151]">
                              <div className="flex justify-center items-center space-x-2">
                                {product.supplier_product_images &&
                                  product.supplier_product_images.length >
                                    0 && (
                                    <>
                                      <img
                                        src={`https://api.rexven.com/images/marketplace/supplier-images/${product.supplier_product_images[0]}`}
                                        alt="producta"
                                        className="w-12 h-12 rounded-lg"
                                      />
                                      <p className="w-64">
                                        {product.supplier_product_variant_name}
                                      </p>
                                    </>
                                  )}
                                {!product.supplier_product_variant_id && (
                                  <p className="text-sm">
                                    Bu ürün henüz eşleştirilmemiş
                                  </p>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="flex justify-center items-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    localProduct
                                      ? localProduct.isImageSaved
                                      : product.isImageSaved
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, product.product_id)
                                  }
                                />
                              </div>
                            </td>
                            <td className="text-xs md:text-sm text-[#374151]">
                              <div className="flex justify-center items-center">
                                {!product.supplier_product_variant_id ? (
                                  <button
                                    className="bg-[#4F46E5] rounded-md space-x-2 px-4 py-1 flex justify-center items-center text-white"
                                    onClick={() => handleProductMatch(product)}
                                  >
                                    <Link1 size={20} color="#fff" />
                                    <p>Eşleştir</p>
                                  </button>
                                ) : (
                                  <button
                                    className="bg-white border-[#4F46E5] border rounded-md space-x-2 px-4 py-1 flex justify-center items-center text-[#4F46E5]"
                                    onClick={() => handleProductMatch(product)}
                                  >
                                    <Edit2 size={20} color="#4F46E5" />
                                    <p>Yeniden Eşleştir</p>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderMatchingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedOrder={selectedOrder}
        getOrders={refetch}
      />
    </div>
  );
};

export default ProductVariationsTable;
