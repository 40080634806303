import React from 'react';
import { classNames } from '../../utils/conditionalClasses';

interface Props {
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}
const DemoStatusSection: React.FC<Props> = ({ status, setStatus }) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const newLabel = e.currentTarget?.getAttribute('data-value') as string;

    setStatus(newLabel);
  };
  return (
    <div className="flex space-x-2 justify-between items-stretch w-full overflow-x-auto px-4">
      <div className="flex-none">
        <button
          className={classNames(
            status === ''
              ? 'text-white bg-[#2563EB]'
              : 'text-[#2563EB] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#2563EB] px-2 p-1 w-[100px] xl:w-[160px]'
          )}
          onClick={handleClick}
          data-value=""
        >
          <p className=" text-xs xl:text-sm">Hepsi</p>
        </button>
      </div>

      {
        <div className="flex-none">
          <button
            data-value="waitingMatch"
            className={classNames(
              status === 'waitingMatch'
                ? 'text-white bg-[#4F46E5]'
                : 'text-[#4F46E5] bg-white',
              'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#2563EB] px-2 p-1 w-[100px] xl:w-[160px]'
            )}
            onClick={handleClick}
          >
            <p className="text-xs xl:text-sm">
              Eşleşme Bekliyor <span>(2)</span>
            </p>
          </button>
        </div>
      }

      {
        <div className="flex-none">
          <button
            className={classNames(
              status === 'waitingPayment'
                ? 'text-white bg-[#0D9488]'
                : 'text-[#0D9488] bg-white',
              'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#0D9488] px-2 p-1 w-[100px] xl:w-[160px]'
            )}
            data-value="waitingPayment"
            onClick={handleClick}
          >
            <p className=" text-xs xl:text-sm">
              Ödeme Bekliyor <span>(2)</span>
            </p>
          </button>
        </div>
      }

      <div className="flex-none">
        <button
          className={classNames(
            status === 'waitingProducing'
              ? 'text-white bg-[#EC4899]'
              : 'text-[#EC4899] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#EC4899] px-2 p-1 w-[100px] xl:w-[160px]'
          )}
          data-value="waitingProducing"
          onClick={handleClick}
        >
          <p className="text-xs xl:text-sm">
            Üretim Bekliyor <span>(5)</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            status === 'producing'
              ? 'text-white bg-[#7C3AED]'
              : 'text-[#7C3AED] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#7C3AED] px-2 p-1 w-[100px] xl:w-[160px]'
          )}
          data-value="producing"
          onClick={handleClick}
        >
          <p className=" text-xs xl:text-sm">
            Üretiliyor <span>(3)</span>
          </p>
        </button>
      </div>

      <div className="flex-none">
        <button
          className={classNames(
            status === 'shipped'
              ? 'text-white bg-[#0EA5E9]'
              : 'text-[#0EA5E9] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#0EA5E9] px-2 p-1 w-[100px] xl:w-[160px]'
          )}
          data-value="shipped"
          onClick={handleClick}
        >
          <p className="text-xs xl:text-sm">
            Kargoya Verildi <span>(2)</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            status === 'delivered'
              ? 'text-white bg-[#059669]'
              : 'text-[#059669] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#059669] px-2 p-1 w-[100px] xl:w-[160px]'
          )}
          data-value="delivered"
          onClick={handleClick}
        >
          <p className=" text-xs xl:text-sm">
            Teslim Edildi <span>(6)</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            status === 'cancelled'
              ? 'text-white bg-red-500'
              : 'text-red-500 bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-red-500 px-2 p-1 w-[100px] xl:w-[160px]'
          )}
          data-value="cancelled"
          onClick={handleClick}
        >
          <p className=" text-xs xl:text-sm">
            İptal Edildi <span>(0)</span>
          </p>
        </button>
      </div>
      {
        <div className="flex-none">
          <button
            data-value="hidden"
            className={classNames(
              status === 'hidden'
                ? 'text-white bg-[#6B7280]'
                : 'text-[#6B7280] bg-white',
              'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#6B7280] px-2 p-1 w-[100px] xl:w-[160px]'
            )}
            onClick={handleClick}
          >
            <p className="text-xs xl:text-sm">
              Gizli <span>(0)</span>
            </p>
          </button>
        </div>
      }
    </div>
  );
};

export default DemoStatusSection;
