import {
  Copy,
  DocumentDownload,
  Edit2,
  EmptyWallet,
  InfoCircle,
  Link2,
  Truck,
} from 'iconsax-react';
import React from 'react';
import { classNames } from '../../utils/conditionalClasses';
import { ClipLoader } from 'react-spinners';
import { formatDateTR } from '../../utils/date';
import { Tooltip } from 'react-tooltip';
import ReactCountryFlag from 'react-country-flag';
import API_URL from '../../config';
import { DocumentPlusIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import DemoShipmentGroup from './DemoShipmentGroup';

const tableHeader = [
  { name: 'Seç', column: 'choose' },
  { name: 'Sipariş', column: 'orderId' },
  { name: 'Fotoğraf', column: 'product' },

  { name: 'Detay', column: 'details' },

  { name: 'Fiyat', column: 'totalPrice' },
  { name: 'Kargo', column: 'shipment' },

  {
    name: 'Tasarım',
    column: 'design',
  },
  {
    name: 'İşlemler',
    column: 'edit',
  },
];
const data: any = [
  {
    id: '228256',
    address: 'Corina Braeuer Boehmerwaldstr. 33 Grasbrunn Oberbayern 85630 DE',
    fullName: 'Corina Braeuer',
    orderDate: '2024-10-17T21:41:18.0000000',
    total: '40.07',
    itemQuantity: 1,
    email: 'braeuers@arcor.de',
    currency: 'USD',
    gsmNumber: null,
    isCustom: false,
    isSecret: false,
    platformOrderId: '19083262',
    createdAt: '2024-10-18T05:20:48.138Z',
    updatedAt: '2024-10-18T05:20:48.138Z',
    userId: 67,
    sellerShopId: '57',
    shipmentCost: 0,
    serviceCode: null,
    carrierCode: null,
    allShipmentCost: [],
    trackingNumber: null,
    labelPdf: null,
    sellerShopName: 'N. Jewelry',
    seller_order_items: [
      {
        id: '138077',
        totalPrice: '44.89',
        quantity: 1,
        label: 'waitingMatch',
        note: null,
        isCustom: false,
        personalization: 'Noela, Kreuz und Oktober Geburtsstein',
        documents: [],
        createdAt: '2024-10-18T05:20:48.138Z',
        updatedAt: '2024-10-18T05:20:48.138Z',
        sellerOrderId: '228256',
        sellerProductId: '112360',
        supplierOrderId: null,
        supplierOrderProductVariantPrice: null,
        suppplierProductVariantImages: null,
        supplierProductVariantAttributes: null,
        supplierProductVariantSku: null,
        supplierProductName: null,
        supplierOrderNeedsDesign: null,
        customizationData: {},
        designFiles: [],
        mockupFiles: null,
        seller_product: {
          id: '112360',
          title:
            'Silver Name Bracelet with Birthstone Cross Bracelet with Handwritten Name Dainty Christian Bracelet Cross Charm Baptism Bracelet',
          images: [
            'https://i.etsystatic.com/35385665/r/il/76864e/5962174818/il_fullxfull.5962174818_oic1.jpg',
          ],
          attributes: [
            {
              FINISH: 'Silver-B.stone&Cross',
              'BRACELET LENGTH': '6 Inches',
            },
          ],
          isCustom: false,
          platformProductId: '4296553791',
          createdAt: '2024-10-18T05:20:48.138Z',
          updatedAt: '2024-10-18T05:20:48.138Z',
          supplierProductVariantId: null,
          sellerProductImagesSaved: true,
          designImages: null,
          mockupImages: null,
        },
        supplier_order: {
          id: null,
          total: null,
          currency: null,
          orderDate: null,
          createdAt: null,
          updatedAt: null,
          sellerUserId: null,
          supplierId: null,
          supplier: {
            userId: null,
            name: null,
          },
        },
      },
    ],
    seller_etsy_orders: {
      etsyOrderId: '19083262',
      shippingMethod: 'Standard Shipping',
      productPrice: '40.07',
      shippingPrice: '0.0',
      quantityTotal: 1,
      handlingPrice: '0',
      email: 'braeuers@arcor.de',
      taxPrice: '6.4',
      discountPrice: '0',
      totalPrice: '40.07',
      currencyCode: 'USD',
      orderDate: '2024-10-17T21:41:18.000Z',
      customerFirstName: 'Corina Braeuer',
      customerLastName: '',
      customerCompany: null,
      customerAddress1: 'Boehmerwaldstr. 33',
      customerAddress2: '',
      customerAddress3: null,
      customerAddress4: null,
      customerCity: 'Grasbrunn',
      customerState: 'Oberbayern',
      customerPostalCode: '85630',
      customerCountry: 'DE',
      customerPhone: null,
      giftMessage: null,
      isGift: false,
      buyerMessage: null,
      shipByDate: '2024-10-25T15:59:00.000Z',
      createdAt: '2024-10-18T05:20:48.138Z',
      updatedAt: '2024-10-18T05:20:48.138Z',
      userId: 67,
      sellerOrderId: '228256',
    },
  },
  {
    id: '228255',
    address: 'Keller Hayes 5460 WARD RD STE 300 ARVADA CO 80002-1800 US',
    fullName: 'Keller Hayes',
    orderDate: '2024-10-17T15:36:25.0000000',
    total: '27.14',
    itemQuantity: 1,
    email: 'keller@dawa-enterprises.com',
    currency: 'USD',
    gsmNumber: null,
    isCustom: false,
    isSecret: false,
    platformOrderId: '19051504',
    createdAt: '2024-10-18T05:20:48.138Z',
    updatedAt: '2024-10-18T05:20:48.138Z',
    userId: 67,
    sellerShopId: '57',
    shipmentCost: 0,
    serviceCode: null,
    carrierCode: null,
    allShipmentCost: [],
    trackingNumber: null,
    labelPdf: null,
    sellerShopName: 'N. Jewelry',
    seller_order_items: [
      {
        id: '138076',
        totalPrice: '33.52',
        quantity: 1,
        label: 'waitingMatch',
        note: null,
        isCustom: false,
        personalization: 'Best Volunteer Ever - TCG Day - October',
        documents: [],
        createdAt: '2024-10-18T05:20:48.138Z',
        updatedAt: '2024-10-18T05:20:48.138Z',
        sellerOrderId: '228255',
        sellerProductId: '112615',
        supplierOrderId: null,
        supplierOrderProductVariantPrice: null,
        suppplierProductVariantImages: null,
        supplierProductVariantAttributes: null,
        supplierProductVariantSku: null,
        supplierProductName: null,
        supplierOrderNeedsDesign: null,
        customizationData: {},
        designFiles: [],
        mockupFiles: null,
        seller_product: {
          id: '112615',
          title:
            'Personalized Gold Birthstone Necklace Women Name Necklace with Birthstone Circle Necklace Message Engraved on Circle Silver Engraved Jewelry',
          images: [
            'https://i.etsystatic.com/35385665/r/il/30e4f1/5555909365/il_fullxfull.5555909365_qifn.jpg',
          ],
          attributes: [
            {
              'Necklace Length': '18 inches',
              'Color - Quantity of Birthstones': 'Silver-1 stone',
            },
          ],
          isCustom: false,
          platformProductId: '4296217201',
          createdAt: '2024-10-18T05:20:48.138Z',
          updatedAt: '2024-10-18T05:20:48.138Z',
          supplierProductVariantId: null,
          sellerProductImagesSaved: true,
          designImages: null,
          mockupImages: null,
        },
        supplier_order: {
          id: null,
          total: null,
          currency: null,
          orderDate: null,
          createdAt: null,
          updatedAt: null,
          sellerUserId: null,
          supplierId: null,
          supplier: {
            userId: null,
            name: null,
          },
        },
      },
    ],
    seller_etsy_orders: {
      etsyOrderId: '19051504',
      shippingMethod: 'Standard Shipping',
      productPrice: '27.14',
      shippingPrice: '0.0',
      quantityTotal: 1,
      handlingPrice: '0',
      email: 'keller@dawa-enterprises.com',
      taxPrice: '2.0',
      discountPrice: '0',
      totalPrice: '27.43',
      currencyCode: 'USD',
      orderDate: '2024-10-17T15:36:25.000Z',
      customerFirstName: 'Keller Hayes',
      customerLastName: '',
      customerCompany: null,
      customerAddress1: '5460 WARD RD STE 300',
      customerAddress2: '',
      customerAddress3: null,
      customerAddress4: null,
      customerCity: 'ARVADA',
      customerState: 'CO',
      customerPostalCode: '80002-1800',
      customerCountry: 'US',
      customerPhone: null,
      giftMessage: null,
      isGift: false,
      buyerMessage: null,
      shipByDate: '2024-10-24T15:59:00.000Z',
      createdAt: '2024-10-18T05:20:48.138Z',
      updatedAt: '2024-10-18T05:20:48.138Z',
      userId: 67,
      sellerOrderId: '228255',
    },
  },
  {
    id: '228254',
    address: 'Ellis Cook 25 Stope Avenue Pontefract Wakefield WF9 5FP GB',
    fullName: 'Ellis Cook',
    orderDate: '2024-10-17T13:28:28.0000000',
    total: '30.02',
    itemQuantity: 1,
    email: 'ellismelissacook@gmail.com',
    currency: 'USD',
    gsmNumber: null,
    isCustom: false,
    isSecret: false,
    platformOrderId: '19033613',
    createdAt: '2024-10-18T05:20:48.138Z',
    updatedAt: '2024-10-18T05:20:48.138Z',
    userId: 67,
    sellerShopId: '57',
    shipmentCost: 0,
    serviceCode: null,
    carrierCode: null,
    allShipmentCost: [],
    trackingNumber: null,
    labelPdf: null,
    sellerShopName: 'N. Jewelry',
    seller_order_items: [
      {
        id: '138075',
        totalPrice: '40.03',
        quantity: 1,
        label: 'waitingMatch',
        note: null,
        isCustom: false,
        personalization: 'J B R R',
        documents: [],
        createdAt: '2024-10-18T05:20:48.138Z',
        updatedAt: '2024-10-18T05:20:48.138Z',
        sellerOrderId: '228254',
        sellerProductId: '112920',
        supplierOrderId: null,
        supplierOrderProductVariantPrice: null,
        suppplierProductVariantImages: null,
        supplierProductVariantAttributes: null,
        supplierProductVariantSku: null,
        supplierProductName: null,
        supplierOrderNeedsDesign: null,
        customizationData: {},
        designFiles: [],
        mockupFiles: null,
        seller_product: {
          id: '112920',
          title:
            'Personalized Initial Mom Bracelet Dainty Gold Initial Bracelet Letter Charm Bracelet Sideways Initial Bracelet Grandma Heart Charm Bracelet',
          images: [
            'https://i.etsystatic.com/35385665/r/il/b26f7a/5553466254/il_fullxfull.5553466254_ltu7.jpg',
          ],
          attributes: [
            {
              Length: '7 Inches',
              'Color and Quantity of Letters': 'Gold - 4 Letters',
            },
          ],
          isCustom: false,
          platformProductId: '4296074519',
          createdAt: '2024-10-18T05:20:48.138Z',
          updatedAt: '2024-10-18T05:20:48.138Z',
          supplierProductVariantId: null,
          sellerProductImagesSaved: true,
          designImages: null,
          mockupImages: null,
        },
        supplier_order: {
          id: null,
          total: null,
          currency: null,
          orderDate: null,
          createdAt: null,
          updatedAt: null,
          sellerUserId: null,
          supplierId: null,
          supplier: {
            userId: null,
            name: null,
          },
        },
      },
    ],
    seller_etsy_orders: {
      etsyOrderId: '19033613',
      shippingMethod: 'Standard Shipping',
      productPrice: '30.02',
      shippingPrice: '0.0',
      quantityTotal: 1,
      handlingPrice: '0',
      email: 'ellismelissacook@gmail.com',
      taxPrice: '0.0',
      discountPrice: '0',
      totalPrice: '30.02',
      currencyCode: 'USD',
      orderDate: '2024-10-17T13:28:28.000Z',
      customerFirstName: 'Ellis Cook',
      customerLastName: '',
      customerCompany: null,
      customerAddress1: '25 Stope Avenue',
      customerAddress2: '',
      customerAddress3: null,
      customerAddress4: null,
      customerCity: 'Pontefract',
      customerState: 'Wakefield',
      customerPostalCode: 'WF9 5FP',
      customerCountry: 'GB',
      customerPhone: null,
      giftMessage: null,
      isGift: false,
      buyerMessage: null,
      shipByDate: '2024-10-24T15:59:00.000Z',
      createdAt: '2024-10-18T05:20:48.138Z',
      updatedAt: '2024-10-18T05:20:48.138Z',
      userId: 67,
      sellerOrderId: '228254',
    },
  },
  {
    id: '228253',
    address:
      'Roo Prince Villa Languard Middle Warberry Road Torquay Torbay TQ1 1RS GB',
    fullName: 'Roo Prince',
    orderDate: '2024-10-17T13:21:40.0000000',
    total: '34.79',
    itemQuantity: 1,
    email: 'roop6@icloud.com',
    currency: 'USD',
    gsmNumber: null,
    isCustom: false,
    isSecret: false,
    platformOrderId: '19033617',
    createdAt: '2024-10-18T05:20:48.138Z',
    updatedAt: '2024-10-18T05:20:48.138Z',
    userId: 67,
    sellerShopId: '57',
    shipmentCost: 0,
    serviceCode: null,
    carrierCode: null,
    allShipmentCost: [],
    trackingNumber: null,
    labelPdf: null,
    sellerShopName: 'N. Jewelry',
    seller_order_items: [
      {
        id: '138074',
        totalPrice: '38.66',
        quantity: 1,
        label: 'waitingMatch',
        note: null,
        isCustom: false,
        personalization: null,
        documents: [],
        createdAt: '2024-10-18T05:20:48.138Z',
        updatedAt: '2024-10-18T05:20:48.138Z',
        sellerOrderId: '228253',
        sellerProductId: '112706',
        supplierOrderId: null,
        supplierOrderProductVariantPrice: null,
        suppplierProductVariantImages: null,
        supplierProductVariantAttributes: null,
        supplierProductVariantSku: null,
        supplierProductName: null,
        supplierOrderNeedsDesign: null,
        customizationData: {},
        designFiles: [],
        mockupFiles: null,
        seller_product: {
          id: '112706',
          title:
            "Dainty Gold Turquoise Necklace for Women Minimalist 925 Silver Blue Stone Turquoise Jewelry Mom Mother's Day Gift Gemstone Necklace Pendant",
          images: [
            'https://i.etsystatic.com/35385665/r/il/1948ef/5124242267/il_fullxfull.5124242267_aixy.jpg',
          ],
          attributes: [
            {
              FINISH: 'Gold',
              LENGTH: '20 inches',
            },
          ],
          isCustom: false,
          platformProductId: '4296066003',
          createdAt: '2024-10-18T05:20:48.138Z',
          updatedAt: '2024-10-18T05:20:48.138Z',
          supplierProductVariantId: null,
          sellerProductImagesSaved: true,
          designImages: null,
          mockupImages: null,
        },
        supplier_order: {
          id: null,
          total: null,
          currency: null,
          orderDate: null,
          createdAt: null,
          updatedAt: null,
          sellerUserId: null,
          supplierId: null,
          supplier: {
            userId: null,
            name: null,
          },
        },
      },
    ],
    seller_etsy_orders: {
      etsyOrderId: '19033617',
      shippingMethod: 'Standard Shipping',
      productPrice: '34.79',
      shippingPrice: '0.0',
      quantityTotal: 1,
      handlingPrice: '0',
      email: 'roop6@icloud.com',
      taxPrice: '5.8',
      discountPrice: '0',
      totalPrice: '34.79',
      currencyCode: 'USD',
      orderDate: '2024-10-17T13:21:40.000Z',
      customerFirstName: 'Roo Prince',
      customerLastName: '',
      customerCompany: null,
      customerAddress1: 'Villa Languard',
      customerAddress2: 'Middle Warberry Road',
      customerAddress3: null,
      customerAddress4: null,
      customerCity: 'Torquay',
      customerState: 'Torbay',
      customerPostalCode: 'TQ1 1RS',
      customerCountry: 'GB',
      customerPhone: null,
      giftMessage: null,
      isGift: false,
      buyerMessage: null,
      shipByDate: '2024-10-24T15:59:00.000Z',
      createdAt: '2024-10-18T05:20:48.138Z',
      updatedAt: '2024-10-18T05:20:48.138Z',
      userId: 67,
      sellerOrderId: '228253',
    },
  },
  {
    id: '228252',
    address: 'Sarah Smith 5 Hainault Drive Verwood Dorset BH31 6LF GB',
    fullName: 'Sarah Smith',
    orderDate: '2024-10-17T12:52:17.0000000',
    total: '17.21',
    itemQuantity: 1,
    email: 'Sms9781@outlook.com',
    currency: 'USD',
    gsmNumber: null,
    isCustom: false,
    isSecret: false,
    platformOrderId: '19033616',
    createdAt: '2024-10-18T05:20:48.138Z',
    updatedAt: '2024-10-18T05:20:48.138Z',
    userId: 67,
    sellerShopId: '57',
    shipmentCost: 0,
    serviceCode: null,
    carrierCode: null,
    allShipmentCost: [],
    trackingNumber: null,
    labelPdf: null,
    sellerShopName: 'N. Jewelry',
    seller_order_items: [
      {
        id: '138073',
        totalPrice: '22.95',
        quantity: 1,
        label: 'waitingMatch',
        note: null,
        isCustom: false,
        personalization: 'Obi\nFont 13\nDog Ears 50',
        documents: [],
        createdAt: '2024-10-18T05:20:48.138Z',
        updatedAt: '2024-10-18T05:20:48.138Z',
        sellerOrderId: '228252',
        sellerProductId: '112788',
        supplierOrderId: null,
        supplierOrderProductVariantPrice: null,
        suppplierProductVariantImages: null,
        supplierProductVariantAttributes: null,
        supplierProductVariantSku: null,
        supplierProductName: null,
        supplierOrderNeedsDesign: null,
        customizationData: {},
        designFiles: [],
        mockupFiles: null,
        seller_product: {
          id: '112788',
          title:
            'Sterling Silver Dog Name Necklace Dog Ears Necklace Pet Memorial Necklace Dog Memorial Necklace with Nameplate Personalized Dog Mom Necklace',
          images: [
            'https://i.etsystatic.com/35385665/r/il/00e0a5/5601181879/il_fullxfull.5601181879_gda7.jpg',
          ],
          attributes: [
            {
              FINISH: 'Rose',
              LENGTH: '14 inches',
            },
          ],
          isCustom: false,
          platformProductId: '4289002686',
          createdAt: '2024-10-18T05:20:48.138Z',
          updatedAt: '2024-10-18T05:20:48.138Z',
          supplierProductVariantId: null,
          sellerProductImagesSaved: true,
          designImages: null,
          mockupImages: null,
        },
        supplier_order: {
          id: null,
          total: null,
          currency: null,
          orderDate: null,
          createdAt: null,
          updatedAt: null,
          sellerUserId: null,
          supplierId: null,
          supplier: {
            userId: null,
            name: null,
          },
        },
      },
    ],
    seller_etsy_orders: {
      etsyOrderId: '19033616',
      shippingMethod: 'Standard Shipping',
      productPrice: '17.21',
      shippingPrice: '0.0',
      quantityTotal: 1,
      handlingPrice: '0',
      email: 'Sms9781@outlook.com',
      taxPrice: '0.0',
      discountPrice: '0',
      totalPrice: '17.21',
      currencyCode: 'USD',
      orderDate: '2024-10-17T12:52:17.000Z',
      customerFirstName: 'Sarah Smith',
      customerLastName: '',
      customerCompany: null,
      customerAddress1: '5 Hainault Drive',
      customerAddress2: '',
      customerAddress3: null,
      customerAddress4: null,
      customerCity: 'Verwood',
      customerState: 'Dorset',
      customerPostalCode: 'BH31 6LF',
      customerCountry: 'GB',
      customerPhone: null,
      giftMessage: null,
      isGift: false,
      buyerMessage: null,
      shipByDate: '2024-10-24T15:59:00.000Z',
      createdAt: '2024-10-18T05:20:48.138Z',
      updatedAt: '2024-10-18T05:20:48.138Z',
      userId: 67,
      sellerOrderId: '228252',
    },
  },
  {
    id: '228204',
    address:
      'Loz Abdale House High Street Cheltenham Gloucestershire GL54 3EU GB',
    fullName: 'Loz',
    orderDate: '2024-10-17T09:18:54.0000000',
    total: '24.0',
    itemQuantity: 1,
    email: 'laurence.parker@hotmail.co.uk',
    currency: 'USD',
    gsmNumber: null,
    isCustom: false,
    isSecret: false,
    platformOrderId: '18985668',
    createdAt: '2024-10-17T16:40:29.781Z',
    updatedAt: '2024-10-17T16:40:29.781Z',
    userId: 67,
    sellerShopId: '57',
    shipmentCost: 22.824,
    serviceCode: 'usps_first_class_mail_international',
    carrierCode: 'stamps_com',
    allShipmentCost: [
      {
        carrierCode: 'stamps_com',
        serviceCode: 'usps_first_class_mail_international',
        shipmentCost: 22.824,
      },
    ],
    trackingNumber: null,
    labelPdf: null,
    sellerShopName: 'N. Jewelry',
    seller_order_items: [
      {
        id: '138023',
        totalPrice: '32.0',
        quantity: 1,
        label: 'waitingPayment',
        note: null,
        isCustom: false,
        personalization: 'July',
        documents: [],
        createdAt: '2024-10-17T16:40:29.781Z',
        updatedAt: '2024-10-17T16:40:29.781Z',
        sellerOrderId: '228204',
        sellerProductId: '112256',
        supplierOrderId: null,
        supplierOrderProductVariantPrice: '6',
        suppplierProductVariantImages: ['REX-001-000097-1.webp'],
        supplierProductVariantAttributes: [],
        supplierProductVariantSku: 'REX-001-000003',
        supplierProductName: 'T-shirt Youth - Gildan 64000b - Tişört Genç\t',
        supplierOrderNeedsDesign: true,
        customizationData: {},
        designFiles: [],
        mockupFiles: null,
        seller_product: {
          id: '112256',
          title:
            'Sun and Moon Birthstone Necklace Custom Birthstone Moon and Sun Necklace Celestial Necklace Spiritual Necklace for Mom June Birthstone Gifts',
          images: [
            'https://i.etsystatic.com/35385665/r/il/f05359/6042167175/il_fullxfull.6042167175_iozq.jpg',
          ],
          attributes: [
            {
              'LENGTH - COLOUR': '18 Inches',
              'BIRTHSTONE QUANTITY': 'Gold',
            },
          ],
          isCustom: false,
          platformProductId: '4295760535',
          createdAt: '2024-10-17T16:40:29.781Z',
          updatedAt: '2024-10-17T16:49:47.687Z',
          supplierProductVariantId: '2725',
          sellerProductImagesSaved: true,
          designImages: null,
          mockupImages: null,
        },
        supplier_order: {
          id: null,
          total: null,
          currency: null,
          orderDate: null,
          createdAt: null,
          updatedAt: null,
          sellerUserId: null,
          supplierId: null,
          supplier: {
            userId: null,
            name: null,
          },
        },
      },
    ],
    seller_etsy_orders: {
      etsyOrderId: '18985668',
      shippingMethod: 'Standard Shipping',
      productPrice: '24.0',
      shippingPrice: '0.0',
      quantityTotal: 1,
      handlingPrice: '0',
      email: 'laurence.parker@hotmail.co.uk',
      taxPrice: '0.0',
      discountPrice: '0',
      totalPrice: '24.0',
      currencyCode: 'USD',
      orderDate: '2024-10-17T09:18:54.000Z',
      customerFirstName: 'Loz',
      customerLastName: '',
      customerCompany: null,
      customerAddress1: 'Abdale House',
      customerAddress2: 'High Street',
      customerAddress3: null,
      customerAddress4: null,
      customerCity: 'Cheltenham',
      customerState: 'Gloucestershire',
      customerPostalCode: 'GL54 3EU',
      customerCountry: 'GB',
      customerPhone: null,
      giftMessage: null,
      isGift: true,
      buyerMessage: null,
      shipByDate: '2024-10-24T15:59:00.000Z',
      createdAt: '2024-10-17T16:40:29.781Z',
      updatedAt: '2024-10-17T16:40:29.781Z',
      userId: 67,
      sellerOrderId: '228204',
    },
  },
];
const cargoPrices = data.map((order: any) => {
  return {
    orderId: order.id,
    shipmentCost: order.shipmentCost,
    allShipmentCost: order.allShipmentCost,
    orderItems: order.seller_order_items,
    serviceCode: order.serviceCode,
  };
});
const DemoTable = () => {
  const renderButton = (label: string, order: any) => {
    if (label === 'waitingMatch') {
      //burada cargoprices içinden order id ile eşleşen bir değer var mı kontrolü yapılmalı
      return (
        <button
          className={
            'text-white bg-[#4F46E5] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex items-center justify-center space-x-2 cursor-pointer'
          }
          // disabled={selectedOrders.length === 0}
        >
          <Link2 color="white" size="24" variant="Bold" /> <p>{'Eşleştir'}</p>
        </button>
      );
    } else if (label === 'waitingPayment') {
      return (
        <button
          className={
            'text-white bg-[#0D9488] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex justify-center items-center space-x-2 cursor-pointer'
          }
        >
          <EmptyWallet size="24" color="white" variant="Bold" />{' '}
          <p>Ödeme Yap</p>
        </button>
      );
    } else if (label === 'waitingProducing' || label === 'producing') {
      if (order.labelPdf === null) {
        return (
          <button
            className="text-white bg-[#F59E0B] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex items-center justify-center space-x-2 cursor-pointer"
            disabled={order.labelPdf !== null}
          >
            <DocumentDownload size="24" color="white" variant="Bold" />{' '}
            <p>Label Al</p>
          </button>
        );
      }
    }
  };
  return (
    <div className="flow-root">
      <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
        <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
          <div className=" flex flex-col flex-shrink-0 bg-[#F9FAFB] rounded-xl mt-8">
            <div className="relative overflow-y-auto overflow-x-auto scrollbar-hide">
              <div className="inline-block min-w-full align-middle h-[100vh]">
                <table className="min-w-[1280px] w-full">
                  <thead>
                    <tr>
                      {tableHeader.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className="sticky top-0 z-10 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                            // scope="col"
                          >
                            {' '}
                            <p className="text-xs md:text-sm">{item.name}</p>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-[#F9FAFB]">
                    <tr className="h-10"></tr>
                    {data?.map((order: any, idx: number) => {
                      return (
                        <tr
                          key={idx}
                          className={classNames(
                            idx === 0 ? 'border-none' : 'border-b-lightGray'
                          )}
                        >
                          <td className="px-1 text-xs md:text-sm sm:pl-0 ">
                            <div className="flex justify-center">
                              {order?.seller_order_items[0]?.label ===
                                'waitingPayment' && (
                                <input
                                  type="checkbox"
                                  className="rounded-sm border-blue-500"
                                />
                              )}
                            </div>
                          </td>
                          {/* <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                          <p className="text-center text-gray">
                            {idx + 20 * (Number(page) - 1) + 1}
                          </p>
                        </td> */}
                          <td className="py-4 text-xs sm:pl-0 whitespace-nowrap text-gray-500">
                            <div className="flex flex-col items-start space-y-1">
                              <p>
                                <span className="text-headerPrimary">
                                  Tarih:
                                </span>{' '}
                                {formatDateTR(order.orderDate)}
                              </p>
                              <p>
                                <span className="text-headerPrimary">
                                  Son tarih:{' '}
                                </span>
                                {order.seller_etsy_orders?.shipByDate &&
                                  formatDateTR(
                                    order.seller_etsy_orders.shipByDate
                                  )}{' '}
                              </p>
                              <p>
                                <span className="text-headerPrimary">
                                  Sipariş no:{' '}
                                </span>
                                {order?.platformOrderId}
                              </p>
                              <p>
                                <span className="text-headerPrimary">
                                  Müşteri:{' '}
                                </span>
                                {order?.fullName}
                              </p>
                              <div className="flex items-center space-x-1">
                                <p className="text-headerPrimary">Ülke: </p>
                                <p>
                                  {order?.seller_etsy_orders.customerCountry}
                                </p>
                                <Tooltip
                                  anchorSelect={`#address${idx}`}
                                  content={
                                    order?.seller_etsy_orders?.customerAddress1
                                  }
                                  style={{
                                    width: '200px',
                                  }}
                                />
                                <ReactCountryFlag
                                  id={`address${idx}`}
                                  className="ml-1 text-base"
                                  svg
                                  countryCode={
                                    order?.seller_etsy_orders.customerCountry
                                  }
                                />
                              </div>

                              {renderButton(
                                order?.seller_order_items[0]?.label,
                                order
                              )}
                            </div>
                          </td>
                          <td className="px-2">
                            <div
                              className={classNames(
                                order?.seller_order_items.length > 1
                                  ? 'space-y-2'
                                  : 'space-y-0',
                                'px-2 py-2 flex flex-col items-center justify-center'
                              )}
                            >
                              {order.seller_order_items.map((item: any) => {
                                return (
                                  <div className="relative">
                                    {item.seller_product.title ===
                                      'Gift Wrap' ||
                                    item.seller_product.title === '' ? (
                                      <img
                                        className="h-[160px] w-[160px] aspect-square object-cover object-center rounded-lg"
                                        src={'/images/giftwrap1.webp'}
                                        alt="Product"
                                      />
                                    ) : (
                                      <div className="relative">
                                        <img
                                          className=" h-[160px] w-[160px] aspect-square object-cover object-center rounded-lg mb-2"
                                          src={
                                            item.seller_product.images[0]
                                              ? item.seller_product.images[0]
                                              : '/images/image-not-found.png'
                                          }
                                          alt="Product"
                                        />
                                      </div>
                                    )}

                                    {item.seller_product.title !==
                                      'Gift Wrap' &&
                                      item.seller_product.title !== '' && (
                                        <button className="py-1 px-4 bg-white text-[#1D4ED8] border border-[#1D4ED8] rounded-lg flex items-center justify-center w-[160px]">
                                          <Edit2 color="#1D4ED8" size={18} />
                                          <p className="text-xs">Güncelle</p>
                                        </button>
                                      )}
                                  </div>
                                );
                              })}
                            </div>
                          </td>

                          <td className="py-4 text-xs md:text-sm text-left overflow-hidden text-gray-500 sm:pl-0">
                            <div className="flex flex-col items-start space-y-4">
                              {order.seller_order_items.map(
                                (item: any, index: any) => (
                                  <div
                                    key={index}
                                    className="flex items-center space-x-4 px-2"
                                  >
                                    {/* "text-xs text-left text-[#1F2937] overflow-hidden h-16 font-medium" */}
                                    <div className="flex flex-col space-y-1 h-[200px] py-4 w-48 justify-center">
                                      <p
                                        className={classNames(
                                          item.seller_product.title ===
                                            'Gift Wrap'
                                            ? 'ml-12'
                                            : 'ml-0',
                                          'text-xs text-left text-[#1F2937] font-medium'
                                        )}
                                      >
                                        {item.seller_product.title.substring(
                                          0,
                                          44
                                        )}
                                        ...
                                      </p>

                                      {item.seller_product.title !==
                                        'Gift Wrap' && (
                                        <p className="text-xs text-[#1F2937]">
                                          Adet:{' '}
                                          <span className="text-gray-500">
                                            {item.quantity}
                                          </span>
                                        </p>
                                      )}

                                      {item.seller_product.title !==
                                        'Gift Wrap' && (
                                        <div className="text-xs text-left text-gray-500 min-w-[160px] h-[200px] overflow-y-auto">
                                          {item.seller_product.attributes
                                            .length > 0 &&
                                            Object.entries(
                                              item.seller_product.attributes[0]
                                            ).map(([key, value]) => {
                                              if (
                                                !key.includes('CustomizedURL')
                                              ) {
                                                return (
                                                  <div
                                                    className="flex space-x-1"
                                                    key={`${index}-${key}`}
                                                  >
                                                    <p className="text-[#1F2937]">
                                                      {key}:
                                                    </p>
                                                    <p>{value as string}</p>
                                                  </div>
                                                );
                                              } else {
                                                return null;
                                              }
                                            })}
                                          {(item.personalization ||
                                            item.customizationData.length >
                                              0) && (
                                            <p className="font-medium text-[#1F2937]">
                                              Kişiselleştirme:{' '}
                                              <span className="font-normal">
                                                {item.personalization
                                                  ? item.personalization
                                                  : item.customizationData
                                                  ? item.customizationData.map(
                                                      (
                                                        item: any,
                                                        index: number
                                                      ) => {
                                                        if (index > 8)
                                                          return <p>{item}</p>;
                                                      }
                                                    )
                                                  : '-'}
                                              </span>
                                            </p>
                                          )}
                                        </div>
                                      )}
                                      <p className="font-medium text-[#1F2937] text-xs flex">
                                        Ürün hediye mi?:
                                        <span className="font-normal ml-1">
                                          {order.seller_etsy_orders.isGift ===
                                          true
                                            ? 'Evet'
                                            : 'Hayır'}{' '}
                                        </span>
                                        <span
                                          className="ml-1"
                                          id={`isGift${idx}`}
                                        >
                                          <InfoCircle
                                            size={16}
                                            color="#111827"
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                )
                              )}
                              <Tooltip
                                anchorSelect={`#isGift${idx}`}
                                content={
                                  `Mesaj:${
                                    order?.seller_etsy_orders.giftMessage
                                      ? order?.seller_etsy_orders.giftMessage
                                      : '-'
                                  }` +
                                  '/ ' +
                                  `Alıcı Mesajı:${
                                    order?.seller_etsy_orders.buyerMessage
                                      ? order?.seller_etsy_orders.buyerMessage
                                      : '-'
                                  }`
                                }
                              />
                            </div>
                          </td>

                          <td className="py-4 text-center text-xs md:text-xs text-gray-500">
                            <div className="flex flex-col items-center space-y-1 w-28">
                              <div>
                                <span className="text-lg text-center font-medium text-headerPrimary">
                                  {order.currency === 'USD'
                                    ? '$'
                                    : order.currency}{' '}
                                  {Number(order.total).toFixed(2)}
                                </span>
                              </div>

                              {order?.seller_order_items[0]
                                ?.supplierOrderProductVariantPrice && (
                                <div className="w-28">
                                  <span className="text-[#1F2937]">Alış:</span>$
                                  {order?.seller_order_items[0]
                                    ?.supplierOrderProductVariantPrice
                                    ? order?.seller_order_items
                                        .reduce(
                                          (acc: number, item: any) =>
                                            acc +
                                            item.quantity *
                                              item.supplierOrderProductVariantPrice *
                                              1.1,
                                          0
                                        )
                                        .toFixed(2)
                                    : ' -'}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="py-4 text-xs md:text-sm text-gray-500 sm:pl-0">
                            <div className="flex flex-col justify-center items-center w-full">
                              {cargoPrices &&
                              cargoPrices?.find((item: any) => {
                                return (
                                  Number(item.orderId) === Number(order.id)
                                );
                              })?.allShipmentCost.length > 0 ? (
                                <DemoShipmentGroup
                                  allShipmentCost={
                                    cargoPrices?.find((item: any) => {
                                      return (
                                        Number(item.orderId) ===
                                        Number(order.id)
                                      );
                                    })?.allShipmentCost
                                  }
                                />
                              ) : (
                                <div className="w-full px-2 py-4 rounded-xl flex space-x-2 justify-center items-center bg-[#FFFBEB]">
                                  {order?.seller_order_items.some(
                                    (item: any) =>
                                      item.supplierOrderProductVariantPrice ===
                                      null
                                  ) && <InfoCircle size={24} color="#F59E0B" />}
                                  <p className="text-[#F59E0B] text-xs">
                                    {order?.seller_order_items.some(
                                      (item: any) =>
                                        item.supplierOrderProductVariantPrice ===
                                        null
                                    ) ? (
                                      'Lütfen tüm ürünleri eşleştiriniz'
                                    ) : (
                                      <ClipLoader color="#F59E0B" />
                                    )}
                                  </p>
                                </div>
                              )}
                              {order.trackingNumber && (
                                <div className="rounded-lg px-2 w-44">
                                  <button className="text-xs mt-2 border-gray-500 border-2 rounded-lg px-2 py-1 text-center mb-2 flex cursor-pointer focus:bg-gray-500 focus:text-white">
                                    {order?.trackingNumber.substring(0, 16)}
                                    ...
                                    <Copy
                                      size={18}
                                      color="#374151"
                                      className="ml-1"
                                    />
                                  </button>
                                  <a
                                    className="flex space-x-2 cursor-pointer border-2 border-[#EA580C] rounded-lg px-2 py-1 text-white mx-auto items-center"
                                    target="_blank"
                                    rel="noreferrer"
                                    //   href={decideTracking(
                                    //     order?.serviceCode,
                                    //     order?.trackingNumber
                                    //   )}
                                  >
                                    <Truck size={24} color="#EA580C" />
                                    <p className="text-[#EA580C] text-xs">
                                      Kargo Takip
                                    </p>
                                  </a>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="w-64 p-2">
                              {order?.seller_order_items?.map((item: any) => {
                                return (
                                  <div className="mb-2 p-1 flex flex-col justify-center h-[200px] w-full items-center border-lightGray rounded-md">
                                    <div className="flex">
                                      {item?.seller_product
                                        ?.sellerProductImagesSaved
                                        ? item?.seller_product?.mockupImages?.map(
                                            (mockup: any, index: number) => {
                                              return (
                                                <img
                                                  onLoad={() => {}}
                                                  className="w-32 aspect-square object-contain object-center rounded-lg"
                                                  src={`${API_URL}${mockup}`}
                                                  alt="Mockup"
                                                />
                                              );
                                            }
                                          )
                                        : item.mockupFiles &&
                                          item.mockupFiles.map(
                                            (mockup: any, index: number) => {
                                              return (
                                                <img
                                                  onLoad={() => {}}
                                                  className="w-32 aspect-square object-contain object-center rounded-lg"
                                                  src={`${API_URL}${mockup}`}
                                                  alt="Mockup"
                                                />
                                              );
                                            }
                                          )}
                                    </div>
                                    {item.supplierOrderNeedsDesign && (
                                      <div className="flex justify-center w-full space-x-2">
                                        {item.seller_product?.mockupImages
                                          ?.length > 0 && (
                                          <button className=" border-2 border-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2">
                                            <Edit2 color="#FB923C" size={18} />
                                            <p className="text-[#FB923C] text-xs">
                                              Düzenle
                                            </p>
                                          </button>
                                        )}

                                        {(item.seller_product?.mockupImages
                                          ?.length < 2 ||
                                          item.seller_product?.mockupImages ===
                                            null) && (
                                          <button
                                            className="bg-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                            disabled={true}
                                          >
                                            <DocumentPlusIcon
                                              color="white"
                                              width={24}
                                            />
                                            <p className="text-white text-xs">
                                              Ekle
                                            </p>
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                          <td>
                            <div className="w-32">
                              {order?.seller_order_items[0].label !==
                                'waitingMatch' &&
                                order?.seller_order_items[0].label !==
                                  'waitingPayment' && (
                                  <Link
                                    id="message"
                                    target="_blank"
                                    to={`/messages?to=${order?.seller_order_items[0]?.supplier_order.supplier.userId}&name=${order?.seller_order_items[0]?.supplier_order.supplier.name}`}
                                    // &name=${order?.seller_order.user.firstName}

                                    className="w-full mb-2 block text-center text-xs text-[#1D4ED8] border-2 px-1 py-1 border-[#1D4ED8] rounded-md"
                                  >
                                    Mesaj Gönder
                                  </Link>
                                )}
                              <button className="w-full text-center text-xs text-white border-2 px-1 py-1 border-[#1D4ED8] bg-[#1D4ED8] rounded-md">
                                Not Ekle
                              </button>
                              <button className="w-full text-center text-xs text-white mt-2 border-2 px-1 py-1 border-[#6B7280] bg-[#6B7280] rounded-md">
                                {order.seller_order_items[0].label === 'hidden'
                                  ? 'Göster'
                                  : 'Gizle'}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoTable;
