import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import stripeService from '../../services/stripeService';
import alertNotification from '../../utils/alertNotification';
import { useAuth } from '../../context/AuthContext';
import { useLocation, useParams } from 'react-router-dom';
import PaymentLoading from '../common/PaymentLoading';
import { STRIPE_KEY } from '../../config';

const stripePromise = loadStripe(STRIPE_KEY);

interface Props {
  country: string;
  platform?: string;
}
const StripeCheckoutForm: React.FC<Props> = ({ country, platform }) => {
  const { session } = useAuth();
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const couponValue = searchParams.get('coupon');
  const getCheckout = async (coupon: string) => {
    setLoading(true);

    try {
      const res = await stripeService.initializeCheckout({
        userId: session?.id!,
        packagePlanId: Number(params?.plan!)
          ? Number(params?.plan)
          : Number(searchParams.get('plan')),
        couponCode: coupon!,
        platform,
      });

      setClientSecret(res.data);
    } catch (error: any) {
      alertNotification('error', error.response.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (session?.location !== 'TR' ) {
    getCheckout(couponValue!);
    // }
  }, [couponValue, country]);

  return (
    <div className="sm:min-w-[400px]">
      <div id="checkout" className="w-full">
        {loading && <PaymentLoading loading />}
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
};

export default StripeCheckoutForm;
