import React, { useEffect, useState } from 'react';
import {
  Bag,
  BagHappy,
  Money4,
  UserSearch,
  Wallet,
  WalletAdd,
  WalletMinus,
} from 'iconsax-react';
import { useQuery } from '@tanstack/react-query';

import Filters from '../components/marketplaceSuppliersProducts/Filters';
import MobileProductCard from '../components/marketplaceSuppliersProducts/MobileProductCard';
import SummaryCard from '../components/marketplaceSuppliersProducts/SummaryCard';
import marketplaceService from '../services/marketplaceService';
import ProductsTable, {
  Product,
} from '../components/marketplaceSuppliersProducts/ProductsTable';
import { useAuth } from '../context/AuthContext';
export interface Store {
  id: string;
  name: string;
  storeId: number;
  platform: string;
  shipentegraStore: number;
  createdAt: string;
  updatedAt: string;
  userId: number;
}
const MarketplaceSuppliersProducts = () => {
  const { session } = useAuth();
  const [loading, setLoading] = useState(false);
  const [sellerProducts, setSellerProducts] = useState([]);
  const [demoData, setDemoData] = useState<Product[]>([
    {
      id: '20558',
      isImageSaved: true,
      title: 'Demo Halloween T-Shirt, Gift for Halloween',
      images: [
        'https://i.etsystatic.com/25457903/r/il/f68eeb/6143228088/il_fullxfull.6143228088_kzju.jpg',
      ],
      totalSales: '5',
      totalRevenue: '124.95',
      averagePrice: '24.99',
      supplier_product_variant: {
        id: '1',
        price: '24.99',
        name: 'Halloween T-Shirt',
        imageList: ['REX-001-000097-1.webp'],
      },
    },
    {
      id: '20559',
      isImageSaved: true,
      title: 'Demo Halloween Town T-Shirt, Womens Halloween T-Shirt',
      images: [
        'https://i.etsystatic.com/25457903/r/il/f68eeb/6143228088/il_fullxfull.6143228088_kzju.jpg',
      ],
      totalSales: '3',
      totalRevenue: '74.97',
      averagePrice: '24.99',
      supplier_product_variant: null,
    },
    {
      id: '20560',
      isImageSaved: false,
      title: 'Demo Trick or Treat Sweatshirt, Halloween Candy Shirt',
      images: [
        'https://i.etsystatic.com/25457903/r/il/4bbf5b/6161371352/il_fullxfull.6161371352_p15h.jpg',
      ],
      totalSales: '4',
      totalRevenue: '99.96',
      averagePrice: '24.99',
      supplier_product_variant: {
        id: '2',
        price: '26.99',
        name: 'Trick or Treat Sweatshirt',
        imageList: ['REX-001-000097-1.webp'],
      },
    },
    {
      id: '20561',
      isImageSaved: true,
      title: 'Demo Madam President Shirt, Vote Democrat T-shirt',
      images: [
        'https://i.etsystatic.com/25457903/r/il/a75159/6180215302/il_fullxfull.6180215302_3bwu.jpg',
      ],
      totalSales: '2',
      totalRevenue: '52.98',
      averagePrice: '26.49',
      supplier_product_variant: {
        id: '3',
        price: '26.49',
        name: 'Madam President Shirt',
        imageList: ['REX-001-000097-1.webp'],
      },
    },
    {
      id: '20562',
      isImageSaved: false,
      title: 'Demo The First but Not The Last, Kamala Harris 2024',
      images: [
        'https://i.etsystatic.com/25457903/r/il/61cbdb/6170035310/il_fullxfull.6170035310_8111.jpg',
      ],
      totalSales: '3',
      totalRevenue: '86.97',
      averagePrice: '28.99',
      supplier_product_variant: null,
    },
    {
      id: '20563',
      isImageSaved: true,
      title: 'Demo Hakuna Matata Sweatshirt, Family Vacation',
      images: [
        'https://i.etsystatic.com/25457903/r/il/7926a1/6247342211/il_fullxfull.6247342211_7yaa.jpg',
      ],
      totalSales: '2',
      totalRevenue: '84.00',
      averagePrice: '42.00',
      supplier_product_variant: {
        id: '4',
        price: '42.00',
        name: 'Hakuna Matata Sweatshirt',
        imageList: ['REX-001-000097-1.webp'],
      },
    },
  ]);

  // const [isLoading, setIsLoading] = useState(false)
  // const [error, setError] = useState(false)
  const [sellerShops, setSellerShops] = useState<Store[]>([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [inputPage, setInputPage] = useState<string | number>(1);
  const [selectedSellerShop, setSelectedSellerShop] = useState<Store>(
    sellerShops[0]
  );
  const { isLoading, error, data } = useQuery<any, Error>(
    ['amazonArbitrage', { page, keyword, selectedSellerShop }],
    {
      queryFn: () =>
        marketplaceService.getMarketplaceSellerProducts(
          page,
          keyword,
          +selectedSellerShop.id
        ),

      staleTime: Infinity,
      refetchOnWindowFocus: false,
      // enabled: !!session?.isMarketplaceSeller,
    }
  );
  const handleSubmit = (selected: Store, searchValue: string) => {
    setPage(1);
    setInputPage(1);
    setSelectedSellerShop(selected);
    setKeyword(searchValue);
  };

  const getMarketplaceSellerShops = () => {
    setLoading(true);
    marketplaceService
      .getMarketplaceSellerShops()
      .then((res) => {
        setSellerShops(res.data.data);
        setSelectedSellerShop(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMarketplaceSellerShops();
  }, []);

  useEffect(() => {
    setTotalPageCount(data?.data?.totalPageCount);
  }, [data]);

  return (
    <>
      {/* <SummaryCard isLoading={isLoading} summary={summary} /> */}
      <Filters
        shops={sellerShops}
        selected={selectedSellerShop}
        setSelected={setSelectedSellerShop}
        handleSubmit={handleSubmit}
      />
      {/* <MobileProductCard
        totalPageCount={session?.isMarketplaceSeller ? totalPageCount : 1}
        page={page}
        isLoading={isLoading}
        setPage={setPage}
        inputPage={inputPage}
        setInputPage={setInputPage}
        data={
          session?.isMarketplaceSeller
            ? data?.data.sellerProductsWithSalesData
            : demoData
        }
        queryString={''}
      /> */}
      <ProductsTable
        totalPageCount={session?.isMarketplaceSeller ? totalPageCount : 1}
        page={page}
        isLoading={isLoading}
        setPage={setPage}
        inputPage={inputPage}
        setInputPage={setInputPage}
        data={
          session?.isMarketplaceSeller
            ? data?.data.sellerProductsWithSalesData
            : demoData
        }
        queryString={''}
      />
    </>
  );
};

export default MarketplaceSuppliersProducts;
