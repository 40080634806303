import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import alertNotification from '../../utils/alertNotification';
import marketplaceService from '../../services/marketplaceService';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  order: any;
}
const ResendOrderModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  order,
}) => {
  const resendOrder = async () => {
    try {
      await marketplaceService.resendOrder({ orderId: order.orderId });
      alertNotification(
        'success',
        'Siparişiniz 10 dk içerisinde sisteme düşecektir.'
      );
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-center py-4 leading-6 text-gray-900"
                >
                  Siparişi tekrar gönder
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-xs text-center text-gray-500">
                    {
                      'Bu siparişe ait yeniden gönderilecek ürünler aşağıdadır. '
                    }
                  </p>
                  {
                    <>
                      <div className="flex justify-center items-center flex-col">
                        {
                          <div className="flex items-center justify-start space-x-4 my-2">
                            {}
                            <div className="flex items-center">
                              {order.orderItems.map((item: any) => {
                                return (
                                  <>
                                    <img
                                      src={item.seller_product.images[0]}
                                      alt=""
                                      className="w-12 h-12 object-cover"
                                    />
                                    <div className="ml-2">
                                      <h3 className="text-sm font-semibold">
                                        {Object.entries(
                                          item.seller_product.attributes[0]
                                        ).map(([key, value]) => {
                                          return <p>{key + ':' + value}</p>;
                                        })}
                                      </h3>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        }
                      </div>
                      <div className="w-full flex justify-end space-x-2">
                        <button
                          onClick={() => {
                            setIsModalOpen(false);
                          }}
                          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
                        >
                          İptal
                        </button>
                        <button
                          onClick={() => {
                            resendOrder();
                            setIsModalOpen(false);
                          }}
                          className="bg-[#1D4ED8] text-white px-4 py-2 rounded-md"
                        >
                          Onayla
                        </button>
                      </div>
                    </>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ResendOrderModal;
