import { CloseCircle, Lock, LockCircle, PlayCircle, SearchNormal, Unlock } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { compact } from '@headlessui/react/dist/utils/render';

import courseService from '../../services/courseService';
import memberService from '../../services/memberService';
import { classNames } from '../../utils/conditionalClasses';
import ProductNotFound from '../common/ProductNotFound';
import NotMemberModal from '../shared/NotMemberModal';
import CourseCard from './CourseCard';

interface CourseInterface {
  courseLocation: string;
  courseName: string;
  createdAt: string;
  description: string;
  isFree: boolean;
  updatedAt: string;
  id: number;
  hasAccess?: boolean;
  courseImage?: string;
}

interface NewCourse {
  id: number;
  courseName: string;
  courseLocation: string;
  isFree: boolean;
}

interface Section {
  id: number;
  sectionName: string;
  sectionLocation: string;
  newCourseId: number;
  newCourse: NewCourse;
}

interface Content {
  id: number;
  contentName: string;
  type: string;
  path: string;
  duration: string;
  notes: string
  section: Section;
}
interface PackagePlan {
  courses: number[];
}

interface CourseResponse {
  packagePlan?: PackagePlan;
}

interface SearchResults {
  data: Content[];
  courses?: CourseResponse | null;
}

const highlightText = (text: string, highlight: string) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ textDecorationColor: '#2563EB', fontWeight: 'bold', color: '#2563EB' }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
};

const extractSectionNumber = (sectionLocation: string) => {
  const match = sectionLocation.match(/\/(\d+)-/);
  return match ? match[1] : '';
};

const CoursesCardWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [courses, setCourses] = useState<CourseInterface[]>([]);
  const [query, setQuery] = useState('');
  const [value] = useDebounce(query, 500);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [notMemberOpen, setNotMemberOpen] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchResults | null>()
  const getCourses = async () => {
    const data = await Promise.all([
      courseService.getCourses(),
      memberService.getMemberCourses(),
    ]);
    const coursesData = data[0].data;
    const memberCourses = data[1].data && data[1].data.packagePlan.courses ? data[1].data.packagePlan.courses : [];

    const result = coursesData.map((course: CourseInterface) => {
      course.hasAccess = memberCourses.includes(course.id) || course.isFree;
      course.courseImage = `/images${course.courseLocation}.webp`;
      return course;
    });

    setCourses(result);
  };
  const groupByNewCourse = (data: Content[]) => {
    return data.reduce((acc: { [key: number]: Content[] }, item: Content) => {
      const courseId = item.section.newCourse.id;
      if (!acc[courseId]) {
        acc[courseId] = [];
      }
      acc[courseId].push(item);
      return acc;
    }, {});
  };

  // useEffect(() => {
  //   if (value.trim() !== '') {
  //     setLoading(true);
  //     courseService.getSearchResults(value)
  //       .then((res) => setSearchResults(res.data))
  //       .catch((err) => setError(true))
  //       .finally(() => setLoading(false));
  //   } else {
  //     setSearchResults(null);
  //   }
  // }, [value]);
  useEffect(() => {
    if (value.trim() !== '') {
      setLoading(true);
      courseService.getSearchResults(value)
        .then((res) => setSearchResults(res.data))
        .catch((err) => setError(true))
        .finally(() => setLoading(false));

      // Update URL with search query
      navigate(`?query=${value}`, { replace: true });
    } else {
      setSearchResults(null);
      navigate('', { replace: true }); // Clear query from URL
    }
  }, [value, navigate]);

  useEffect(() => {
    getCourses();
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('query');
    if (searchQuery) {
      setQuery(searchQuery);
    }
  }, [location.search]);
  let content;
  if (searchResults && searchResults.data.length > 0) {
    const groupedResults = groupByNewCourse(searchResults.data);
    content = (
      <>
        {Object.entries(groupedResults).map(([courseId, contents]) => (
          <div className='flex flex-col gap-4 items-start justify-start lg:border-b-2 py-4 w-full' key={courseId}>
            <div className="grid grid-cols-12 gap-4 items-start justify-start w-full">
              <img
                className='col-span-12 md:col-span-4 lg:col-span-2 rounded-lg'
                src={`/images${contents[0].section.newCourse.courseLocation}.webp`}
                alt={contents[0].section.newCourse.courseName} />
              <div className='col-span-12 md:col-span-8 lg:col-span-10'>
                {contents.map((content: Content) => (
                  <div className='grid grid-cols-12 gap-2 items-start justify-between mb-4 pb-4 border-b-2 lg:border-b-0' key={content.id}>
                    <p className='col-span-12 xl:col-span-2 text-[#1F2937] text-[16px] font-bold uppercase'>{content.section.newCourse.courseName}</p>
                    <p className='col-span-6 xl:col-span-2 text-[#374151] text-[14px] font-bold'>{extractSectionNumber(content.section.sectionLocation)}-{content.section.sectionName}</p>
                    <div className='col-span-6 xl:col-span-4 text-[14px] text-[#374151] cursor-pointer'
                      onClick={() => {
                        if (searchResults.courses?.packagePlan?.courses.includes(content.section.newCourseId) || content.section.newCourse.isFree) {
                          navigate(`/courses/${content.section.newCourse.id}/${content.section.newCourse.courseName}?course=${content.section.newCourse.courseLocation}&section=${content.section.sectionLocation}&path=${content.path}&id=${content.id}&name=${content.contentName}&notes=${content.notes}`);
                        } else {
                          setNotMemberOpen(true)
                        }
                      }}>
                      {highlightText(content.contentName, query)}
                    </div>
                    <div className='col-span-12 xl:col-span-4 grid grid-cols-5 gap-4 items-center justify-start md:justify-between'>
                      <div className='col-span-1 md:col-span-2 text-[14px] text-[#3C4790]'>
                        {Math.round(+content.duration / 60)} dk
                      </div>
                      {searchResults.courses?.packagePlan?.courses.includes(content.section.newCourseId) || content.section.newCourse.isFree ?
                        <div
                          onClick={() => {
                            if (searchResults.courses?.packagePlan?.courses.includes(content.section.newCourseId) || content.section.newCourse.isFree) {
                              navigate(`/courses/${content.section.newCourse.id}/${content.section.newCourse.courseName}?course=${content.section.newCourse.courseLocation}&section=${content.section.sectionLocation}&path=${content.path}&id=${content.id}&name=${content.contentName}&notes=${content.notes}`);
                            } else {
                              setNotMemberOpen(true)
                            }
                          }}
                          className='rounded-lg border-2 border-[#1D4ED8] bg-[#1D4ED8] cursor-pointer col-span-3 md:col-span-2 text-white text-center py-2 font-semibold' >Eğitime Git</div> :
                        <div onClick={() => setNotMemberOpen(true)} className='rounded-lg border-2 border-[#E5E5E6] bg-[#E5E5E6] cursor-pointer col-span-3 md:col-span-2 text-[#818183] text-center py-2 font-semibold' >Eğitime Git</div>}
                      {searchResults.courses?.packagePlan?.courses.includes(content.section.newCourseId) || content.section.newCourse.isFree ?
                        <Unlock variant="Bold" className='cursor-pointer col-span-1 text-[#9CA3AF] w-4 h-4 rounded-full' />
                        :
                        <Lock onClick={() => setNotMemberOpen(true)} variant="Bold" className='cursor-pointer col-span-1 text-[#1D4ED8] w-4 h-4 rounded-full' />
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr />
          </div>
        ))}
        <NotMemberModal
          content={'common.text.notMemberCourses'}
          notMemmberOpen={notMemberOpen}
          setNotMemberOpen={setNotMemberOpen}
        />
      </>
    )
  } else if (searchResults && searchResults.data.length === 0) {
    content = (
      <ProductNotFound />
    );
  } else {
    content = (
      <div className=" grid gap-6 overflow-hidden text-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 ">
        {courses.map((course) => (
          <CourseCard
            key={course.id}
            courseName={course.courseName}
            description={course.description}
            isFree={course.isFree}
            hasAccess={course.hasAccess}
            courseImage={course.courseImage}
            courseId={course.id}
          />
        ))}
      </div>
    );
  }


  return (
    <div className='bg-white p-2 md:p-6 rounded-2xl flex flex-col gap-4 items-center justify-center'>
      <div className=' w-full md:w-[75%]'>
        <div className="relative">
          <div className="absolute inset-y-0 right-5 flex items-center pl-3">
            {!value && <SearchNormal className='w-4 h-4' />}
            {value && <CloseCircle onClick={() => setQuery('')} className='w-4 h-4 cursor-pointer' />}
          </div>
          <input
            className="block w-full p-[0.58rem] pl-5 text-xs md:text-sm text-description border border-headerPrimary rounded-full "
            placeholder={"Örn: Amazon hesabı açma"}
            value={query}
            onChange={(e) => {
              setQuery(e.currentTarget.value);
            }}
            id="query"
            name="query"
            type="text"
          />
        </div>
      </div>
      {content}
    </div>
  );
};
//?section=/1-genel&content=/9-arbitraj-urun-bulma-analiz-ve-urun-listeleme.mp4
export default CoursesCardWrapper;
