import { DollarSquare, ShoppingCart, WalletMinus } from 'iconsax-react';
import React from 'react';
import { seperateNumberWithComma } from '../../utils/numberFormatter';

interface Props {
  data: any;
}
const DemoMyProductsCard: React.FC<Props> = ({ data }) => {
  return (
    <div className="lg:hidden grid grid-cols-12 gap-4 mt-8">
      {data?.map((product: any) => {
        return product?.supplier_product_variant ? (
          <div
            key={product.id}
            className="col-span-12 md:col-span-6 shadow-lg bg-[#f9fafb] rounded-[12px] flex flex-col items-center justify-center py-[32px] px-[16px] mb-[16px]"
            style={{ boxShadow: '0px 4px 12px rgba(17, 24, 39, 0.15)' }}
          >
            <img
              className="h-[150px] w-[150px] rounded-lg"
              src={`${product?.images[0]}`}
              alt="productImg"
            />
            <p className="pt-[16px] pb-[24px] text-[#374151] font-medium text-[14px]">
              Dainty Star of David Necklace Gold Magen David Necklace Silver
              Jewish
            </p>
            <div className="flex flex-row items-center justify-between w-full text-[#374151] font-normal">
              <span className="text-[14px]">Ortalama Satış Fiyatı</span>
              <span className="text-[28px]">
                ${' '}
                {product.averagePrice ? (
                  <span>{`${Number(product.averagePrice).toFixed(2)}`}</span>
                ) : (
                  '-'
                )}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between w-full text-[#374151] font-normal text-[14px] pt-[26px] pb-[18px]">
              <div className="flex flex-row gap-1 items-center justify-start">
                <ShoppingCart color="#F97316" variant="Bold" />
                <span>{product.totalSales}</span>
              </div>
              <div className="flex flex-row gap-1 items-center justify-start">
                <DollarSquare color="#10B981" variant="Bold" />
                <span>
                  ${' '}
                  {product.totalRevenue ? (
                    <span>{`${Number(product.totalRevenue).toFixed(2)}`}</span>
                  ) : (
                    '-'
                  )}
                </span>
              </div>
              <div className="flex flex-row gap-1 items-center justify-start">
                <WalletMinus color="#EF4444" variant="Bold" />
                <span>
                  {product.supplier_product_variant
                    ? seperateNumberWithComma(
                        Number(product.supplier_product_variant.price)
                      )
                    : '-'}
                </span>
              </div>
            </div>
            <div className="border-b border-[#D1D5DB] w-full"></div>
            <div className="w-full flex flex-row items-center justify-between my-[16px] gap-[8px]">
              <img
                className="h-[40px] w-[40px]"
                src={`https://api.rexven.com/images/marketplace/supplier-images/${product?.supplier_product_variant.imageList[0]}`}
                alt="productImg"
              />
              {/* <img src="/images/marketplace/sellerProductSmall.png" alt="productImg" /> */}
              <p className="text-[#374151] text-[12px]">
                {product.supplier_product_variant.name}{' '}
              </p>
            </div>
            {/* <div className='w-full bg-[#faf9fb] border-2 border-[#1D4ED8] text-[#1D4ED8] py-[12px] px-[10px] rounded-[6px] h-[40px] flex items-center justify-center gap-[10px]'>
                              <Edit2 color='#1D4ED8' variant='Bold' />
                              <span className='text-[14px] font-medium'>Güncelle</span>
                          </div> */}
          </div>
        ) : (
          <div
            key={product.id}
            className="col-span-12 md:col-span-6 shadow-lg bg-[#f9fafb] rounded-[12px] flex flex-col items-center justify-center py-[32px] px-[16px] mb-[16px]"
            style={{ boxShadow: '0px 4px 12px rgba(17, 24, 39, 0.15)' }}
          >
            <img
              className="h-[150px] w-[150px] rounded-lg"
              src={`${product?.images[0]}`}
              alt="productImg"
            />
            <p className="pt-[16px] pb-[24px] text-[#374151] font-medium text-[14px]">
              Dainty Star of David Necklace Gold Magen David Necklace Silver
              Jewish
            </p>
            <div className="flex flex-row items-center justify-between w-full text-[#374151] font-normal">
              <span className="text-[14px]">Ortalama Satış Fiyatı</span>
              <span className="text-[28px]">
                ${' '}
                {product.averagePrice ? (
                  <span>{`${Number(product.averagePrice).toFixed(2)}`}</span>
                ) : (
                  '-'
                )}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between w-full text-[#374151] font-normal text-[14px] pt-[26px] pb-[34px]">
              <div className="flex flex-row gap-1 items-center justify-start">
                <ShoppingCart color="#F97316" variant="Bold" />
                <span>34</span>
              </div>
              <div className="flex flex-row gap-1 items-center justify-start">
                <DollarSquare color="#10B981" variant="Bold" />
                <span>
                  ${' '}
                  {Number(
                    product.order_count * product.averageProductSalesPrice
                  ).toFixed(2)}
                </span>
              </div>
              <div className="flex flex-row gap-1 items-center justify-start">
                <WalletMinus color="#EF4444" variant="Bold" />
                <span>${product.averageProductSalesPrice.toFixed(2)}</span>
              </div>
            </div>
            <div className="flex justify-center">
              <p className="bg-[#0284C7] text-white font-medium rounded-lg px-8 py-2">
                Detay
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DemoMyProductsCard;
