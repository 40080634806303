import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProductSummaryCard from '../components/marketplace/ProductSummaryCard';
import ProductDetailFilter from '../components/marketplace/ProductDetailFilter';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './errors/ErrorFallback';
import { useQuery } from '@tanstack/react-query';

import ProductVariationsTable from '../components/marketplace/ProductVariationsTable';
import { useAuth } from '../context/AuthContext';
import marketplaceService from '../services/marketplaceService';
import Pagination from '../components/common/Pagination';

const MarketplaceMyProductDetails = () => {
  const session = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const productId = params.id;
  const [inputPage, setInputPage] = React.useState<number | string>(1);
  const [storeId, setStoreId] = React.useState(
    searchParams.get('storeId') || ''
  );
  const [attribute, setAttribute] = React.useState(
    searchParams.get('attributes') || ''
  );
  const [matching, setMatching] = React.useState(
    searchParams.get('matching') || ''
  );
  const [page, setPage] = React.useState(1);
  const [queryString, setQueryString] = React.useState(
    `storeId=${storeId}&matching=${matching}&page=${page}`
  );

  const { isLoading, error, data, refetch } = useQuery<any, Error>(
    [
      'myMarketplaceProductDetails',
      storeId,
      productId,
      attribute,
      matching,
      page,
      inputPage,
    ],
    {
      queryFn: () =>
        marketplaceService.getMyProductDetails(
          Number(productId),
          matching,
          attribute,
          storeId ? Number(storeId) : '',
          page
        ),
      staleTime: Infinity,
    }
  );
  const handleRefetch = useCallback(() => refetch(), [refetch]);
  useEffect(() => {
    if (session?.isMarketplaceSeller) navigate('/marketplace');
  }, [session]);

  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ProductSummaryCard
          productId={productId!}
          products={data?.data?.sellerProductVariants}
          refetch={handleRefetch}
        />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ProductDetailFilter
          setAttribute={setAttribute}
          setMatching={setMatching}
          setStoreId={setStoreId}
          setQueryString={setQueryString}
        />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ProductVariationsTable
          data={data}
          isLoading={isLoading}
          refetch={handleRefetch}
        />
      </ErrorBoundary>
      <Pagination
        page={Number(page)}
        setPage={setPage}
        totalPageCount={data?.data.totalPageCount!}
        inputPage={inputPage}
        setInputPage={setInputPage}
        queryString={queryString}
      />
    </div>
  );
};

export default MarketplaceMyProductDetails;
