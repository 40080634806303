import { useFormik } from 'formik';
import * as Yup from 'yup';
import { errorElement } from '../../utils/errorElement';
import React, { useEffect, useState } from 'react';
import { Calculator } from 'iconsax-react';
import CustomSelect from './CustomSelect';
import trendyolToolsService from '../../services/trendyolToolsService';
import { Information } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';

interface TrendyolProfitFormProps {
  onSubmit: (event: any, values: any) => Promise<void>;
  sellingPrice: number | null;
  commissionRate: number | undefined;

  setCommissionRate: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export interface CommissionCategories {
  id: number;
  name: string;
  commissionRate: number;
}

const TrendyolCalculateProfitForm: React.FC<TrendyolProfitFormProps> = ({
  onSubmit,
  sellingPrice,
  commissionRate,
  setCommissionRate,
}) => {
  const [categoryInput, setCategoryInput] = useState('');
  const [categories, setCategories] = useState<CommissionCategories[]>();
  const [formattedOptions, setFormattedOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const getCategories = () => {
    trendyolToolsService.getCategories('').then((res) => {
      // console.log(res.data);
      setCategories(res.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      const newFormattedOptions = categories.map((category) => ({
        value: category.id,
        label: category.name,
      }));

      setFormattedOptions(newFormattedOptions);
    }
  }, [categories]);

  const formik = useFormik({
    initialValues: {
      // sellingPrice: sellingPrice ? sellingPrice : 0,
      sellingPrice: '',
      commissionRate: commissionRate ? commissionRate : '',
      costOfPurchase: 0,
      shippingCost: 0,
      vatRate: 18,
      advertisingCost: 0,
      discount: 0,
      laborCost: 0,
      packageCost: 0,
      shippingIncome: 0,
      isPercent: 'Percent',
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      sellingPrice: Yup.number()
        .required('Lütfen Satış Fiyatı Giriniz')
        .min(1, 'Lütfen Satış Fiyatı Giriniz'),
      costOfPurchase: Yup.number().required('Ürün Maliyeti Giriniz'),
      advertisingCost: Yup.number().required('Reklam Maliyeti Giriniz'),
      discount: Yup.number().min(0, "Bu alan 0'dan küçük olamaz"),
      commissionRate: Yup.number()
        .required('Lütfen Komisyon Oranı Giriniz')
        .min(0.1, "Komisyon Oranı 0'dan büyük olmalıdır"),
    }),
  });
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event, formik.values);
  };
  return (
    <>
      <form className="flex flex-col gap-y-[2px] pb-4" onSubmit={handleSubmit}>
        {/* CATEGORY */}
        <div className="flex items-center">
          <div className="w-[77.2%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="costOfPurchase">Kategori</label>
              <Information
                size={20}
                className="cursor-pointer"
                id="categoryToolTip"
              />
              <Tooltip
                anchorSelect="#categoryToolTip"
                content="Ürünlerin belirli özelliklerine veya öğelerine göre sınıflandırıldığı bir yapı"
              />
            </div>
          </div>

          <div className="w-full ">
            {categories && categories.length > 0 && (
              <CustomSelect
                options={categories}
                onSelect={(selectedCategory: CommissionCategories) => {
                  setCategoryInput(selectedCategory.name);
                  setCommissionRate(selectedCategory.commissionRate);
                  formik.setFieldValue(
                    'commissionRate',
                    selectedCategory.commissionRate
                  );
                }}
              />
            )}
          </div>
        </div>

        {/* COMMISSION RATE */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="commissionRate">
                Komisyon Oranı <span className="text-xs">(%)</span>{' '}
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="commissionToolTip"
              />
              <Tooltip
                anchorSelect="#commissionToolTip"
                content="Trendyol'un kategorilere özel belirlediği yüzdesel oran miktarı"
              />
            </div>
          </div>
          <input
            value={formik.values.commissionRate!}
            // value={commissionRate}
            onChange={formik.handleChange}
            name="commissionRate"
            id="commissionRate"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="Lütfen Kategori Seçiniz"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}

          // readOnly
          />
        </div>

        {formik.errors.commissionRate &&
          errorElement(formik.errors.commissionRate)}

        {/* KDV  */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="vatRate">
                KDV Oranı <span className="text-xs">(%)</span>{' '}
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="vatRateToolTip"
              />
              <Tooltip
                anchorSelect="#vatRateToolTip"
                content='Devletin belirlemiş olduğu "Katma Değer Vergisi" oranı'
              />
            </div>
          </div>
          <select
            name="vatRate"
            id="vatRate"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            value={formik.values.vatRate}
            onChange={formik.handleChange}
            defaultValue={20}
          >
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        {formik.errors.vatRate && errorElement(formik.errors.vatRate)}

        <h1 className="w-full border border-[059669] text-xl font-bold text-white text-center rounded-sm bg-[#059669] p-1 my-2">
          GELİR
        </h1>

        {/* SELLING PRICE */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="sellingPrice">
                Satış Fiyatı <span className="text-xs">(₺)</span>{' '}
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="sellingPriceToolTip"
              />
              <Tooltip
                anchorSelect="#sellingPriceToolTip"
                content="Ürünün KDV dahil indirimsiz satış fiyatı"
              />
            </div>
          </div>
          <input
            value={formik.values.sellingPrice}
            onChange={formik.handleChange}
            name="sellingPrice"
            id="sellingPrice"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="250₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>
        {formik.errors.sellingPrice && errorElement(formik.errors.sellingPrice)}

        {/* SHIPPING INCOME */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="shippingIncome">
                Kargo Ücreti <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="shippingIncomeToolTip"
              />
              <Tooltip
                anchorSelect="#shippingIncomeToolTip"
                content="Alıcıdan tahsil edilen KDV dahil kargo ücreti"
              />
            </div>
          </div>
          <input
            value={formik.values.shippingIncome}
            onChange={formik.handleChange}
            name="shippingIncome"
            id="shippingIncome"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="25₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>

        {/* DISCOUNT PLACE */}
        <div className="flex items-center mb-2">
          <div className="w-[78%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="discount">
                İndirim / Kupon <span className="text-xs">(%/₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="discountToolTip"
              />
              <Tooltip
                anchorSelect="#discountToolTip"
                content="Satış fiyatı üzerinden uygulanacak sabit ya da yüzdesel indirim / kupon miktarı"
              />
            </div>
          </div>
          <div className="flex w-full items-center pr-2">
            <input
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 38 || e.keyCode === 40) {
                  // 38 for 'Up' and 40 for 'Down'
                  e.preventDefault();
                }
              }}
              min={0}
              type="number"
              name="discount"
              id="discount"
              max={99}
              onChange={formik.handleChange}
              value={formik.values.discount}
              placeholder="25"
              className="rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm mr-1 bg-white w-4/12"
            />
            <div
              className="flex justify-center items-center w-4/12 ml-2"
              role="group"
            >
              <input
                name="isPercent"
                type="radio"
                defaultChecked={true}
                onChange={formik.handleChange}
                value="Percent"
                className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
              />
              <label
                htmlFor={'isPercent'}
                className="ml-1 block text-xs min-[1335px]:text-sm font-normal min-[1335px]:font-medium  leading-6 text-gray-900 mr-3"
              >
                Yüzde
              </label>
            </div>
            <div className="flex justify-center items-center w-4/12">
              <input
                name="isPercent"
                type="radio"
                value="Fixed"
                onChange={formik.handleChange}
                className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
              />
              <label
                htmlFor={'isPercent'}
                className="ml-1 block text-xs min-[1335px]:text-sm font-normal min-[1335px]:font-medium  leading-6 text-gray-900"
              >
                Sabit
              </label>
            </div>
          </div>
        </div>

        <h1 className="w-full border border-[#DB2777] text-xl font-bold text-white text-center rounded-sm bg-[#DB2777] p-1 mb-2">
          MALİYET
        </h1>

        {/* PRODUCT COST */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="costOfPurchase">
                Ürün Maliyeti <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="costOfPurchaseToolTip"
              />
              <Tooltip
                anchorSelect="#costOfPurchaseToolTip"
                content="Ürünün KDV dahil alış maliyeti"
              />
            </div>
          </div>
          <input
            value={formik.values.costOfPurchase}
            onChange={formik.handleChange}
            name="costOfPurchase"
            id="costOfPurchase"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="50₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>
        {formik.errors.costOfPurchase &&
          errorElement(formik.errors.costOfPurchase)}

        {/* ISCILIK MALIYETI */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="laborCost">
                İşçilik Maliyeti <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="laborCostToolTip"
              />
              <Tooltip
                anchorSelect="#laborCostToolTip"
                content="Ürünün üretiminde kullanılan işgücü maliyeti"
              />
            </div>
          </div>
          <input
            value={formik.values.laborCost}
            onChange={formik.handleChange}
            name="laborCost"
            id="laborCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="50₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>

        {/* PAKETLEME MALIYETI */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="packageCost">
                Paketleme Maliyeti <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="packageCostToolTip"
              />
              <Tooltip
                anchorSelect="#packageCostToolTip"
                content="Ürünün ambalajlama veya paketlenmesi için harcanan KDV dahil maliyet"
              />
            </div>
          </div>
          <input
            value={formik.values.packageCost}
            onChange={formik.handleChange}
            name="packageCost"
            id="packageCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="50₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>

        {/* KARGO MALIYETI */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="shippingCost">
                Kargo Maliyeti <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="shippingCostToolTip"
              />
              <Tooltip
                anchorSelect="#shippingCostToolTip"
                content="Ürünün alıcıya ulaştırılması için kargo firmasına ödenen KDV dahil nakliye maliyeti"
              />
            </div>
          </div>
          <input
            value={formik.values.shippingCost}
            onChange={formik.handleChange}
            name="shippingCost"
            id="shippingCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="50₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>

        {/* AD COST */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="advertisingCost">
                Reklam Maliyeti <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="adCostToolTip"
              />
              <Tooltip
                anchorSelect="#adCostToolTip"
                content="Ürünün tanıtımı için Trendyol'a ödenen maliyet"
              />
            </div>
          </div>
          <input
            value={formik.values.advertisingCost}
            onChange={formik.handleChange}
            name="advertisingCost"
            id="advertisingCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="0₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>
        {formik.errors.advertisingCost &&
          errorElement(formik.errors.advertisingCost)}

        <p className="text-xs text-red-500">
          *Gelir ve Maliyet Fiyatlarına KDV Dahildir.
        </p>

        <button
          className="mt-2 mb-2 text-white font-bold rounded-md  bg-headerPrimary w-full py-2.5 px-2 flex items-center justify-center"
          type="submit"
        >
          <span className="mr-2">Hesapla</span>
          <Calculator size="24" color="#fff" />
        </button>
      </form>
    </>
  );
};

export default TrendyolCalculateProfitForm;
