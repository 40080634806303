import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { ProductCalc } from '../../../pages/EtsyProfitCalculator';
import { Calculator, Information } from 'iconsax-react';
import * as Yup from 'yup';
import { errorElement } from '../../../utils/errorElement';
import { Tooltip } from 'react-tooltip';
import { useLanguage } from '../../../context/LanguageContext';

interface FormProps {
  onSubmit: (event: any, values: any) => void;
  productInfo: ProductCalc | undefined;
  loading: boolean;
  shopCurrency: string;
}

export const COUNTRIES = [
  { id: 1, name: 'Australia' },
  { id: 2, name: 'Austria' },
  { id: 3, name: 'Belgium' },
  { id: 4, name: 'Bulgaria' },
  { id: 5, name: 'Canada' },
  { id: 6, name: 'Croatia' },
  { id: 7, name: 'Cyprus' },
  { id: 8, name: 'Czech Republic' },
  { id: 9, name: 'Denmark' },
  { id: 10, name: 'Estonia' },
  { id: 11, name: 'Finland' },
  { id: 12, name: 'France' },
  { id: 13, name: 'Germany' },
  { id: 14, name: 'Greece' },
  { id: 15, name: 'Hong Kong' },
  { id: 16, name: 'Hungary' },
  { id: 17, name: 'India' },
  { id: 18, name: 'Indonesia' },
  { id: 19, name: 'Ireland' },
  { id: 20, name: 'Israel' },
  { id: 21, name: 'Italy' },
  { id: 22, name: 'Latvia' },
  { id: 23, name: 'Lithuania' },
  { id: 24, name: 'Luxembourg' },
  { id: 25, name: 'Malaysia' },
  { id: 26, name: 'Malta' },
  { id: 27, name: 'Mexico' },
  { id: 28, name: 'Morocco' },
  { id: 29, name: 'Netherlands' },
  { id: 30, name: 'New Zealand' },
  { id: 31, name: 'Norway' },
  { id: 32, name: 'Philippines' },
  { id: 33, name: 'Poland' },
  { id: 34, name: 'Portugal' },
  { id: 35, name: 'Romania' },
  { id: 36, name: 'Singapore' },
  { id: 37, name: 'Slovakia' },
  { id: 38, name: 'Slovenia' },
  { id: 39, name: 'South Africa' },
  { id: 40, name: 'Spain' },
  { id: 41, name: 'Sweden' },
  { id: 42, name: 'Switzerland' },
  { id: 43, name: 'Türkiye' },
  { id: 44, name: 'United Kingdom' },
  { id: 45, name: 'United States' },
  { id: 46, name: 'Vietnam' },
];
const bankCurrencies: any = {
  AUD: '$',
  EUR: '€',
  CAD: '$',
  CZK: '€',
  DKK: 'kr',
  HKD: '$',
  HUF: '€',
  INR: '₹',
  IDR: 'Rp',
  ILS: '₪',
  MYR: 'RM',
  MXN: '$',
  MAD: 'DH',
  NZD: '$',
  NOK: 'kr',
  PHP: '₱',
  PLN: '€',
  SGD: '$',
  ZAR: 'R',
  SEK: 'kr',
  CHF: '₣',
  TRY: '₺',
  GBP: '£',
  USD: '$',
  VND: '₫',
};
const ProfitForm: React.FC<FormProps> = ({
  onSubmit,
  productInfo,
  loading,
  shopCurrency,
}) => {
  const { t, language } = useLanguage();
  const formik = useFormik({
    initialValues: {
      shopLocation: 'Türkiye',
      bankLocation: 'Türkiye',
      shopCurrency: 'USD',
      hasTaxNumber: true,
      offsiteAds: 0,
      isPercent: 'Percent',
      hasFreeListing: 'priced',
      discount: 0,
      salePrice: 0,
      shippingIncome: 0,
      giftBoxIncome: 0,
      tax: 0,
      discountPercentage: 0,
      discountFixed: 0,
      productCost: 0,
      laborCost: 0,
      packageCost: 0,
      shippingCost: 0,
      addCost: 0,
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      salePrice: Yup.number()
        .min(0.01, t?.('formFilter.noZero'))
        .required(t?.('formFilter.cantEmpty')),
      shippingIncome: Yup.number().min(0, t?.('formFilter.noZero')),
      giftBoxIncome: Yup.number().min(0, t?.('formFilter.noZero')),
      tax: Yup.number().min(0, t?.('formFilter.noZero')),
      discount: Yup.number().min(0, t?.('formFilter.noZero')),
      productCost: Yup.number().min(0, t?.('formFilter.noZero')),
      laborCost: Yup.number().min(0, t?.('formFilter.noZero')),
      packageCost: Yup.number().min(0, t?.('formFilter.noZero')),
      shippingCost: Yup.number().min(0, t?.('formFilter.noZero')),
      addCost: Yup.number().min(0, t?.('formFilter.noZero')),
    }),
  });
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event, formik.values);
  };

  useEffect(() => {
    if (productInfo?.originalPrice! > 0) {
      formik.setFieldValue('salePrice', productInfo?.originalPrice);
    } else if (productInfo?.discountRate === 0) {
      formik.setFieldValue('salePrice', productInfo?.discountedPrice);
    }
    formik.setFieldValue('discount', productInfo?.discountRate);
  }, [productInfo]);

  useEffect(() => {
    loading && formik.resetForm();
  }, [loading]);

  return (
    <form className="pb-4" onSubmit={handleSubmit}>
      {/* ShopLocation */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="shopLocation">
              {language === 'tr'
                ? 'Mağaza Sahibi Lokasyonu'
                : 'Store Owner Location'}{' '}
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="shopLocationToolTip"
            />
            <Tooltip anchorSelect="#shopLocationToolTip">
              {language === 'tr' ? (
                <>
                  Mağaza sahibinin ikamet ettiği ülkedir. Yasal işlem ücreti ve
                  KDV hesaplanmasında etkilidir.
                </>
              ) : (
                <>Country of residence of the store owner</>
              )}
            </Tooltip>
          </div>
        </div>

        <select
          name="shopLocation"
          value={formik.values.shopLocation}
          onChange={formik.handleChange}
          id="shopLocation"
          defaultValue={COUNTRIES[42].name}
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        >
          {COUNTRIES.map((country) => {
            return (
              <option key={country.id} value={country.name}>
                {country.name}
              </option>
            );
          })}
        </select>
      </div>

      {/* Bank Location */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1 ">
          <div className="flex justify-between">
            <label htmlFor="bankLocation.şçplçoşi.;">
              {language === 'tr' ? 'Banka Lokasyonu' : 'Bank Location'}{' '}
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="bankLocationToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#bankLocationToolTip">
                Satışlardan elde edilen gelirin yatırıldığı bankanın bulunduğu
                ülkedir.
                <br /> Bankanın ülkesine ait para birimi ne ise tüm Etsy
                kesintileri o para biriminde yapılır.
                <br /> Ödeme işlem ücretinin hesaplanmasında etkilidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#bankLocationToolTip">
                The bank where the income from sales is deposited is located It
                is a country.
              </Tooltip>
            )}
          </div>
        </div>

        <select
          name="bankLocation"
          value={formik.values.bankLocation}
          onChange={formik.handleChange}
          defaultValue={COUNTRIES[42].name}
          id="bankLocation"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        >
          {COUNTRIES.map((country) => {
            return (
              <option key={country.id} value={country.name}>
                {country.name}
              </option>
            );
          })}
        </select>
      </div>

      {/* TaxNumber */}
      <div className="flex items-center mb-2 ">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="hasTaxNumber">
              {language === 'tr' ? 'Vergi Numarası' : 'Tax Number'}{' '}
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="hasTaxNumber"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#hasTaxNumber">
                Bir şirketin ya da şahsın vergi mükellefi olup olmadığını
                belirleyen numaradır.
                <br /> Vergi numaranız var ise "Var", yok ise "Yok" seçiniz.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#hasTaxNumber">
                Whether a company or individual is a taxpayer is the determining
                number.
              </Tooltip>
            )}
          </div>
        </div>

        <div className="flex w-full pr-4 py-2">
          <div className="flex justify-center items-center" role="group">
            <input
              name="hasTaxNumber"
              id="hasTaxNumber"
              defaultChecked={true}
              onChange={formik.handleChange}
              value={'var'}
              type="radio"
              className=" border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'hasTaxNumber'}
              className="ml-3 block text-xs font-normal min-[1335px]:font-medium  leading-6 min-[1335px]:text-sm text-gray-900 mr-3"
            >
              {language === 'tr' ? 'Var' : 'Yes'}
            </label>
          </div>
          <div className="flex justify-center items-center">
            <input
              name="hasTaxNumber"
              id="hasTaxNumber"
              onChange={formik.handleChange}
              type="radio"
              value={'yok'}
              className="border-gray-300 text-cardBorder min-[1335px]:text-sm focus:ring-cardBorder"
            />
            <label
              htmlFor={'hasTaxNumber'}
              className="ml-3 block text-xs font-normal min-[1335px]:font-medium min-[1335px]:text-sm  text-gray-900"
            >
              {language === 'tr' ? 'Yok' : 'No'}
            </label>
          </div>
        </div>
      </div>

      {/* Offsite Ads */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="offsiteAds"> Offsite Ads</label>
            <Information size={20} className="cursor-pointer" id="offsiteAds" />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#offsiteAds">
                Satışınız offsite ads üzerinden geldi ise ve mağazanın son 1
                yıllık cirosu $10.000 altında ise %15, üzerinde ise %12 seçiniz.
                <br />
                Satışınız offsite ads üzerinden gelmedi ise %0 seçiniz.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#offsiteAds">
                If your sale came through offsite ads and the last 1 day of the{' '}
                <br />
                store If the annual turnover is below $10,000, select 15%, if it{' '}
                <br />
                is above $10,000, select 12%.
              </Tooltip>
            )}
          </div>
        </div>

        <div className="flex w-full items-center pr-4 py-2">
          <div className="flex justify-center items-center">
            <input
              name="offsiteAds"
              id="offsiteAds"
              onChange={formik.handleChange}
              value={0}
              type="radio"
              defaultChecked={true}
              className="border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'offsiteAds'}
              className="ml-3 block text-xs font-normal min-[1335px]:font-medium  min-[1335px]:text-sm leading-6 text-gray-900 md:mr-3 lg:mr-3 xl:mr-4"
            >
              %0
            </label>
          </div>
          <div className="flex justify-center items-center">
            <input
              name="offsiteAds"
              id="offsiteAds"
              type="radio"
              value={12}
              onChange={formik.handleChange}
              className=" border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'offsiteAds'}
              className="ml-1 block text-xs font-normal min-[1335px]:font-medium  min-[1335px]:text-sm leading-6 text-gray-900 mr-3"
            >
              %12
            </label>
          </div>
          <div className="flex justify-center items-center">
            <input
              name="offsiteAds"
              type="radio"
              value={15}
              onChange={formik.handleChange}
              className=" border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'offsiteAds'}
              className="ml-1 block text-xs font-normal min-[1335px]:font-medium  min-[1335px]:text-sm leading-6 text-gray-900"
            >
              %15
            </label>
          </div>
        </div>
      </div>

      {/* FreeLİsting */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="hasFreeListing">
              {' '}
              {language === 'tr' ? 'Ücretsiz Listeleme' : 'Free Listing'}{' '}
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="hasFreeListingToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#hasFreeListingToolTip">
                Mağazanıza tanımlı ücretsiz listeme hakkı var ise "Var", yok ise
                "Yok" seçiniz.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#hasFreeListingToolTip">
                If your store has the right to a free list, "Yes", if not,
                Select "None".
              </Tooltip>
            )}
          </div>
        </div>
        <div className="flex w-full items-center pr-4 py-2">
          <div className="flex justify-center items-center">
            <input
              name="hasFreeListing"
              id="hasFreeListing"
              value="priced"
              defaultChecked={true}
              onChange={formik.handleChange}
              type="radio"
              className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'hasFreeListing'}
              className="ml-3 block text-xs font-normal min-[1335px]:font-medium  min-[1335px]:text-sm leading-6 text-gray-900 mr-3"
            >
              {language === 'tr' ? 'Yok' : 'No'}
            </label>
          </div>
          <div className="flex justify-center items-center">
            <input
              name="hasFreeListing"
              id="hasFreeListing"
              onChange={formik.handleChange}
              type="radio"
              value="free"
              className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'hasFreeListing'}
              className="ml-3 block text-xs font-normal min-[1335px]:font-medium  leading-6 min-[1335px]:text-sm text-gray-900 "
            >
              {language === 'tr' ? 'Var' : 'Yes'}
            </label>
          </div>
        </div>
        {/*  Revenue Section */}
      </div>
      {/*  ---  Revenue Section  ----  */}
      <h1 className="w-full border border-[#6EE7B7] text-xl font-bold text-[#134E4A] text-center rounded-sm bg-[#CCFBF1] p-1 mb-2">
        {language === 'tr' ? 'GELİR' : 'REVENUE'}
      </h1>

      {/* Sale Price */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="salePrice">
              {' '}
              {language === 'tr' ? 'Satış Fiyatı' : 'Sale Price'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="salePriceToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#salePriceToolTip">
                Ürünün indirimsiz satış fiyatıdır.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#salePriceToolTip">
                It is the sales price of the product without discount.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          type="number"
          onChange={formik.handleChange}
          value={formik.values.salePrice}
          placeholder="250"
          name="salePrice"
          id="salePrice"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>
      {formik.errors.salePrice &&
        formik.values.salePrice <= 0 &&
        errorElement(formik.errors.salePrice)}

      {/* Shipping Income */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="shippingIncome">
              {language === 'tr' ? 'Kargo Ücreti' : 'Shipping Fee'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="shippingIncomeToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#shippingIncomeToolTip">
                Alıcıdan tahsil edilen kargo ücretidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#shippingIncomeToolTip">
                It is the shipping fee collected from the buyer.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          type="number"
          onChange={formik.handleChange}
          value={formik.values.shippingIncome}
          placeholder="250"
          name="shippingIncome"
          id="shippingIncome"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>

      {/* GiftBox Income */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="giftBoxIncome">
              {language === 'tr' ? 'Hediye Paketi Ücreti' : 'Gift Wrapping Fee'}{' '}
              ({bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="giftBoxIncomeToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#giftBoxIncomeToolTip">
                Alıcıdan tahsil edilen hediye paketi ücretidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#giftBoxIncomeToolTip">
                It is the gift wrapping fee collected from the buyer.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          type="number"
          placeholder="250"
          onChange={formik.handleChange}
          value={formik.values.giftBoxIncome}
          name="giftBoxIncome"
          id="giftBoxIncome"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>

      {/* Tax */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="tax">
              {language === 'tr' ? 'Satış Vergisi' : 'Sales Tax'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information size={20} className="cursor-pointer" id="taxToolTip" />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#taxToolTip">
                Alıcının bulunduğu ülke ya da eyalete göre oranı değişen ve
                alıcıdan <br /> tahsil edilen vergidir. Mevcut bir satıştaki kar
                oranı hesaplanmayacak ise <br />
                tahmini bir değer (satış fiyatının %5'i gibi) girilebilir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#taxToolTip">
                The rate varies depending on the country or state where the{' '}
                <br />
                buyer is located and It is the tax collected from the buyer.
                <br /> If the profit rate on an existing sale will not be <br />
                calculated, the estimated A value (such as 5% of the sales
                price) can be entered.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          placeholder="250"
          name="tax"
          onChange={formik.handleChange}
          value={formik.values.tax}
          id="tax"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>

      {/* Discount */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="discount">
              {language === 'tr' ? 'İndirim / Kupon' : 'Discount / Coupon'} (%/
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="discountToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#discountToolTip">
                Satış fiyatı üzerinden uygulanacak olan sabit ya da yüzdesel
                indirim / kupon miktarıdır.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#discountToolTip">
                Fixed or percentage rate to be applied over the sales price is
                the discount / coupon amount.
              </Tooltip>
            )}
          </div>
        </div>

        <div className="flex w-full items-center pr-2">
          <input
            type="number"
            name="discount"
            id="discount"
            max={99}
            onChange={formik.handleChange}
            value={formik.values.discount}
            placeholder="25"
            className="rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm mr-1 bg-white w-4/12"
          />
          <div
            className="flex justify-center items-center w-4/12 ml-2"
            role="group"
          >
            <input
              name="isPercent"
              type="radio"
              defaultChecked={true}
              onChange={formik.handleChange}
              value="Percent"
              className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'isPercent'}
              className="ml-1 block text-[10px] min-[1335px]:text-[10px] font-normal min-[1335px]:font-medium  leading-6 text-gray-900 mr-3"
            >
              {language === 'tr' ? 'Yüzde' : 'Percentage'}
            </label>
          </div>
          <div className="flex justify-center items-center w-4/12">
            <input
              name="isPercent"
              type="radio"
              value="Fixed"
              onChange={formik.handleChange}
              className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
            />
            <label
              htmlFor={'isPercent'}
              className="ml-1 block text-xs min-[1335px]:text-[10px] font-normal min-[1335px]:font-medium  leading-6 text-gray-900"
            >
              {language === 'tr' ? 'Sabit' : 'Fixed'}
            </label>
          </div>
        </div>
      </div>

      {/* ---- COST Section ---- */}
      <h1 className="w-full border border-[#F87171] text-xl font-bold text-[#B91C1C] text-center rounded-sm bg-[#FEE2E2] p-1 mb-2">
        {language === 'tr' ? 'MALIYET' : 'COST'}
      </h1>

      {/* Product Cost */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="productCost">
              {language === 'tr' ? 'Ürün Maliyeti' : 'Product Cost'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="productCostToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#productCostToolTip">
                Ürünün alış maliyeti ya da üretimi kapsamındaki <br /> malzeme /
                hammadde maliyetidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#productCostToolTip">
                {' '}
                It is the purchase cost of the product or the cost of materials{' '}
                <br />/ raw materials within the scope of its production.{' '}
              </Tooltip>
            )}
          </div>
        </div>

        <input
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          onChange={formik.handleChange}
          value={formik.values.productCost}
          placeholder="250"
          name="productCost"
          id="productCost"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>

      {/* Labor Cost */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="laborCost">
              {language === 'tr' ? 'İşçilik Maliyeti' : 'Labor Cost'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="laborCostToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#laborCostToolTip">
                Ürünün üretiminde kullanılan işgücü maliyetidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#laborCostToolTip">
                It is the labor cost used in the production of the product.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          onChange={formik.handleChange}
          value={formik.values.laborCost}
          placeholder="250"
          name="laborCost"
          id="laborCost"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>

      {/* Package Cost */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="packageCost">
              {language === 'tr' ? 'Paketleme Maliyeti' : 'Packaging Cost'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="packageCostToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#packageCostToolTip">
                Ürünün ambalajlanması veya paketlenmesi için harcanan
                maliyetidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#packageCostToolTip">
                It is the cost spent on packaging or wrapping the product.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          placeholder="250"
          onChange={formik.handleChange}
          value={formik.values.packageCost}
          name="packageCost"
          id="packageCost"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>

      {/* Shipping Cost */}
      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="shippingCost">
              {language === 'tr' ? 'Kargo Maliyeti' : 'Shipping Cost'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="shippingCostToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#shippingCostToolTip">
                Ürünün alıcıya ulaştırılması için kargo firmasına ödenen nakliye
                maliyetidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#shippingCostToolTip">
                Freight paid to the cargo company to deliver the product to the
                buyer is the cost.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          value={formik.values.shippingCost}
          onChange={formik.handleChange}
          placeholder="250"
          name="shippingCost"
          id="shippingCost"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
        />
      </div>

      {/* Ad Cost */}

      <div className="flex items-center mb-2">
        <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <label htmlFor="addCost">
              {language === 'tr' ? 'Reklam Maliyeti' : 'Advertising Cost'} (
              {bankCurrencies[shopCurrency]})
            </label>
            <Information
              size={20}
              className="cursor-pointer"
              id="addCostToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#addCostToolTip">
                Ürünün tanıtımı için Etsy'e ödenen maliyettir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#addCostToolTip">
                It is the cost paid to Etsy to promote the product.
              </Tooltip>
            )}
          </div>
        </div>

        <input
          type="number"
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
              // 38 for 'Up' and 40 for 'Down'
              e.preventDefault();
            }
          }}
          placeholder="250"
          onChange={formik.handleChange}
          value={formik.values.addCost}
          name="addCost"
          id="addCost"
          className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs md:text-sm bg-white"
        />
      </div>
      <button
        type="submit"
        className="my-2 text-white font-bold rounded-md  bg-headerPrimary w-full py-2.5 px-2 flex items-center justify-center"
      >
        <span className="mr-2">
          {language === 'tr' ? 'Hesapla' : 'Calculate'}{' '}
        </span>
        <Calculator size="24" color="#fff" />
      </button>
    </form>
  );
};

export default ProfitForm;
