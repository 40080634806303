import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../context/LanguageContext';
import { ICategory, ICountry, IProductType } from '../../interfaces/etsyToolsInterfaces';
import { errorElement } from '../../utils/errorElement';

interface EtsyTopSellersFormProps {
  topSellerFormSubmit: (values: any) => void;
  categories: ICategory[];
  productTypes: IProductType[];
  countries: ICountry[];
}

const EtsyTopSellersForm: React.FC<EtsyTopSellersFormProps> = ({
  topSellerFormSubmit,
  categories,
  countries,
  productTypes,
}) => {
  const { t } = useLanguage();
  const [countryName, setCountryName] = useState(t?.('common.select'));
  const [date, setDate] = useState(t?.('common.select'));
  const [category, setCategory] = useState(t?.('common.select'));
  const [productType, setProductType] = useState(t?.('common.select'));

  const formik = useFormik({
    initialValues: {
      etsyCountryId: '',
      createDate: '',
      taxonomyId: '',
      etsyProductTypeId: '',
      totalSaleCountMin: '',
      totalSaleCountMax: '',
      // avgSalesMin: '',
      // avgSalesMax: '',
      avgSalesMin: '',
      avgSalesMax: '',
      reviewCountMin: '',
      reviewCountMax: '',
      totalListingCountMin: '',
      totalListingCountMax: '',
      shopName: ''
    },
    onSubmit: topSellerFormSubmit,
    validationSchema: Yup.object().shape({
      reviewCountMin: Yup.number().min(0, t?.('formFilter.noZero')),
      reviewCountMax: Yup.number().when(
        'reviewCountMin',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxReview'))
      ),
      totalSaleCountMin: Yup.number().min(0, t?.('formFilter.noZero')),
      totalSaleCountMax: Yup.number().when(
        'totalSaleCountMin',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxTotalSaleCount'))
      ),
      avgSalesMin: Yup.number().min(0, t?.('formFilter.noZero')),
      avgSalesMax: Yup.number().when(
        'avgSalesMin',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxAvgSales'))
      ),
      totalListingCountMin: Yup.number().min(0, t?.('formFilter.noZero')),
      totalListingCountMax: Yup.number().when(
        'totalListingCountMin',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxTotalListing'))
      ),
    }),
  });

  function classNames(...classes: String[]) {
    return classes.filter(Boolean).join(' ');
  }

  const shopOpeningYears = [
    { id: 20, title: 'Hepsi', value: '' },
    { id: 1, title: '> 2005', value: 2005 },
    { id: 2, title: '> 2006', value: 2006 },
    { id: 3, title: '> 2007', value: 2007 },
    { id: 4, title: '> 2008', value: 2008 },
    { id: 5, title: '> 2009', value: 2009 },
    { id: 6, title: '> 2010', value: 2010 },
    { id: 7, title: '> 2011', value: 2011 },
    { id: 8, title: '> 2012', value: 2012 },
    { id: 9, title: '> 2013', value: 2013 },
    { id: 10, title: '> 2014', value: 2014 },
    { id: 11, title: '> 2015', value: 2015 },
    { id: 12, title: '> 2016', value: 2016 },
    { id: 13, title: '> 2017', value: 2017 },
    { id: 14, title: '> 2018', value: 2018 },
    { id: 15, title: '> 2019', value: 2019 },
    { id: 16, title: '> 2020', value: 2020 },
    { id: 17, title: '> 2021', value: 2021 },
    { id: 18, title: '> 2022', value: 2022 },
    { id: 19, title: '> 2023', value: 2023 },
  ];

  return (
    <div className="bg-white rounded-xl mt-12">
      <form
        className="grid grid-cols-12 gap-4 p-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-span-12">
          <p className="text-description font-medium px-1 mb-1 text-xs md:text-sm">
            {t?.('etsy-top-sellers.filter.store')}
          </p>
          <div className="flex justify-between items-center space-x-2">
            <input
              value={formik.values.shopName}
              onChange={formik.handleChange}
              id="shopName"
              name="shopName"
              type="text"
              className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="CaitlynMinimalist, PlannerKate1, SilverRainSilver ..."
            />
          </div>
          {formik.errors.reviewCountMin &&
            errorElement(formik.errors.reviewCountMin!)}
          {formik.errors.reviewCountMax &&
            errorElement(formik.errors.reviewCountMax!)}
        </div>
        {/* Ülke */}
        <div className="col-span-6 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center mb-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
              htmlFor="etsyCountryId"
            >
              {t?.('etsy-top-sellers.filter.country')}
            </label>

            <Listbox
              value={formik.values.etsyCountryId}
              onChange={(country) => {
                formik.values.etsyCountryId = country;
                let countryTitle = countries?.find(
                  (item) => item.id === Number(formik.values.etsyCountryId)
                )?.name;

                countryTitle = countryTitle ? countryTitle : 'Hepsi';
                setCountryName(countryTitle!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{countryName}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        <Listbox.Option
                          key={98989898}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {countries?.map((country: any) => (
                          <Listbox.Option
                            key={country.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-secondary text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={country.id}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {country.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>

        {/* Mağaza Açılış Yılı */}
        <div className="col-span-6 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="createDate"
            >
              {t?.('etsy-top-sellers.filter.openingYear')}
            </label>
            <Listbox
              value={formik.values.createDate}
              onChange={(date) => {
                formik.values.createDate = date;
                let storeAge = shopOpeningYears.find(
                  (item) => item.value === Number(formik.values.createDate)
                )?.title;

                storeAge = storeAge ? storeAge : 'Hepsi';

                setDate(storeAge!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{date}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        {shopOpeningYears.map((date: any) => (
                          <Listbox.Option
                            key={date.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-secondary text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={date.value}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {date.title}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>

        {/* Kategoriler */}
        <div className="col-span-6 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center mb-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="taxonomyId"
            >
              {t?.('etsy-top-sellers.filter.category')}
            </label>
            <Listbox
              value={formik.values.taxonomyId}
              onChange={(category) => {
                formik.values.taxonomyId = category;
                let categoryName =
                  categories &&
                  categories?.find(
                    (item) => item.id === Number(formik.values.taxonomyId)
                  )?.taxonomyName;
                categoryName = categoryName ? categoryName : 'Hepsi';
                setCategory(categoryName!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{category}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        <Listbox.Option
                          key={7868769}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {categories &&
                          categories?.map((category: any) => (
                            <Listbox.Option
                              key={category.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-secondary text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={category.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {category.taxonomyName}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>

        {/* Ürün Tipi */}
        <div className="col-span-6 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center mb-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="etsyProductTypeId"
            >
              {t?.('etsy-top-sellers.filter.productType')}
            </label>
            <Listbox
              value={formik.values.etsyProductTypeId}
              onChange={(prodType) => {
                formik.values.etsyProductTypeId = prodType;
                let tempType =
                  productTypes &&
                  productTypes?.find(
                    (item) =>
                      item.id === Number(formik.values.etsyProductTypeId)
                  )?.type;
                tempType = tempType ? tempType : 'Hepsi';
                setProductType(tempType!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{productType}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        <Listbox.Option
                          key={746565}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {productTypes &&
                          productTypes?.map((prodType: any) => (
                            <Listbox.Option
                              key={prodType.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-secondary text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={prodType.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {prodType.type}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>

        {/* Toplam Satış */}
        <div className="col-span-12 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center mb-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
              htmlFor="totalSaleCountMin"
            >
              {t?.('etsy-top-sellers.filter.totalSales')}
            </label>
            <div className="flex w-full justify-between items-center space-x-2">
              <input
                name="totalSaleCountMin"
                id="totalSaleCountMin"
                onChange={formik.handleChange}
                type="number"
                value={formik.values.totalSaleCountMin}
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                onChange={formik.handleChange}
                name="totalSaleCountMax"
                id="totalSaleCountMax"
                value={formik.values.totalSaleCountMax}
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.totalSaleCountMax &&
              errorElement(formik.errors.totalSaleCountMax!)}
            {formik.errors.totalSaleCountMin &&
              errorElement(formik.errors.totalSaleCountMin!)}
          </div>
        </div>

        {/* Dünkü Satış */}
        <div className="col-span-12 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center mb-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="dailySales"
            >
              {t?.('etsy-top-sellers.filter.avgDailySales')}
            </label>
            <div className="flex w-full justify-between items-center space-x-2">
              <input
                onChange={formik.handleChange}
                name="avgSalesMin"
                value={formik.values.avgSalesMin}
                id="avgSalesMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                onChange={formik.handleChange}
                name="avgSalesMax"
                value={formik.values.avgSalesMax}
                id="avgSalesMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.avgSalesMin &&
              errorElement(formik.errors.avgSalesMin!)}
            {formik.errors.avgSalesMax &&
              errorElement(formik.errors.avgSalesMax!)}
          </div>
        </div>

        {/* Yorum Sayısı */}
        <div className="col-span-12 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center mb-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="reviewCountMin"
            >
              {t?.('etsy-top-sellers.filter.reviewCount')}
            </label>
            <div className="flex w-full justify-between items-center space-x-2">
              <input
                onChange={formik.handleChange}
                value={formik.values.reviewCountMin}
                name="reviewCountMin"
                id="reviewCountMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                onChange={formik.handleChange}
                value={formik.values.reviewCountMax}
                id="reviewCountMax"
                name="reviewCountMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.reviewCountMax &&
              errorElement(formik.errors.reviewCountMax!)}
            {formik.errors.reviewCountMin &&
              errorElement(formik.errors.reviewCountMin!)}
          </div>
        </div>

        {/* Toplam Ürün */}
        <div className="col-span-12 md:col-span-3 xl:col-span-3 text-description">
          <div className="flex flex-col items-center mb-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="totalListingCountMin"
            >
              {t?.('etsy-top-sellers.filter.totalProduct')}
            </label>
            <div className="flex w-full justify-between items-center space-x-2">
              <input
                onChange={formik.handleChange}
                value={formik.values.totalListingCountMin}
                id="totalListingCountMin"
                name="totalListingCountMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                onChange={formik.handleChange}
                value={formik.values.totalListingCountMax}
                id="totalListingCountMax"
                name="totalListingCountMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.totalListingCountMax &&
              errorElement(formik.errors.totalListingCountMax!)}
            {formik.errors.totalListingCountMin &&
              errorElement(formik.errors.totalListingCountMin!)}
          </div>
        </div>

        <button
          type="submit"
          className="col-span-12 w-1-2 px-4 py-2 text-sm lg:w-3/12 lg:text-base my-2 mx-auto text-white font-bold rounded-md  bg-headerPrimary    flex items-center justify-center"
        >
          {t?.('etsy-top-sellers.filter.filterBtn')}
        </button>
      </form>
    </div>
  );
};

export default EtsyTopSellersForm;
