import React from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import marketplaceService from '../../services/marketplaceService';
import { IShops } from './MyProductFilter';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Props {
  setAttribute: React.Dispatch<React.SetStateAction<string>>;
  setMatching: React.Dispatch<React.SetStateAction<string>>;
  setStoreId: React.Dispatch<React.SetStateAction<string>>;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
}
const ProductDetailFilter: React.FC<Props> = ({
  setAttribute,
  setMatching,
  setStoreId,
  setQueryString,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      storeId: '',
      attribute: '',
      matching: '',
    },
    onSubmit: (values) => {
      setAttribute(values.attribute);
      setMatching(values.matching);
      setStoreId(values.storeId);
      setSearchParams({
        storeId: values.storeId,
        attribute: values.attribute,
        matching: values.matching,
      });
      setQueryString(
        `storeId=${values.storeId}&matching=${values.matching}&attribute=${values.attribute}`
      );
      navigate(
        `?storeId=${values.storeId}&matching=${values.matching}&attribute=${values.attribute}`
      );
    },
  });

  const { error, data } = useQuery<IShops, Error>(['myShops'], {
    queryFn: () => marketplaceService.getMyShops(),
    staleTime: Infinity,
  });
  return (
    <div className="w-full mt-8">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col space-y-2 space-x-0 sm:space-y-0 sm:space-x-4 sm:flex-row justify-start items-center w-full"
      >
        <select
          name="storeId"
          placeholder="Lütfen mağaza seçiniz"
          onChange={formik.handleChange}
          className="border-[#6B7280] rounded-md text-sm w-full lg:w-auto"
        >
          <option value="" className="text-sm">
            Tüm Mağazalar
          </option>
          {data?.data.map((item: any) => {
            return (
              <option value={Number(item.id)} className="text-sm">
                {item.name}
              </option>
            );
          })}
        </select>
        <select
          name="matching"
          placeholder="Eşleşme Durumu"
          onChange={formik.handleChange}
          className="border-[#6B7280] rounded-md text-sm w-full lg:w-auto"
        >
          <option value="" className="text-sm">
            Hepsi
          </option>
          <option value="matched" className="text-sm">
            Eşleşme Yapılanlar
          </option>
          <option value="notMatched" className="text-sm">
            Eşleşme Yapılmayanlar
          </option>
        </select>
        <input
          className="border-[#6B7280] rounded-md px-8 text-sm w-full lg:w-auto"
          type="text"
          onChange={formik.handleChange}
          name="attribute"
          placeholder="Varyasyon Ara"
        />
        <button
          className="bg-[#1D4ED8] rounded-lg px-8 py-2 text-white"
          type="submit"
        >
          Filtrele
        </button>
      </form>
    </div>
  );
};

export default ProductDetailFilter;
