import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import alertNotification from '../../utils/alertNotification';
import { EtsyOrderItem } from '../../pages/MarketplaceOrders';
import marketplaceService from '../../services/marketplaceService';

interface StatusChangeModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrders: any[];
  setSelectedOrders: React.Dispatch<React.SetStateAction<EtsyOrderItem[]>>;
  getSupplierOrders: () => Promise<any>;
  // refetchData: () => void;
}

const StatusChangeModal: React.FC<StatusChangeModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrders,
  setSelectedOrders,
  getSupplierOrders,
  // refetchData,
}) => {
  const [label, setLabel] = useState<string>('');

  const handleSubmitStatusChange = async () => {
    const orderItemIds: any = [];
    selectedOrders.map((item: any) =>
      item.seller_order_items.map((order: any) => {
        orderItemIds.push(order.id);
      })
    );

    try {
      await marketplaceService.updateSupplierOrderLabel({ orderItemIds, label });
      alertNotification('success', 'Durum güncellendi');
      setIsModalOpen(false);
      setSelectedOrders([]);
      getSupplierOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl 3xl:max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-center py-4 leading-6 text-gray-900"
                >
                  Sipariş Durumunu Güncelle
                </Dialog.Title>
                <div className="mt-2 flex space-x-2 items-center">
                  <div className="w-1/2 flex flex-wrap space-y-2">
                    {selectedOrders.map((selectedOrder) => {
                      return selectedOrder.seller_order_items.map(
                        (item: any) => (
                          <div className="flex flex-row items-center">
                            <img
                              src={
                                item?.seller_product.images[0]
                                  ? item?.seller_product.images[0]
                                  : '/images/image-not-found.png'
                              }
                              alt="Order item"
                              className="w-16 h-16 3xl:w-20 3xl:h-20 rounded-xl"
                            />
                            <p className="px-4 text-xs">
                              {String(item?.seller_product.title).substring(
                                0,
                                60
                              )}
                              ...
                            </p>
                          </div>
                        )
                      );
                    })}
                  </div>
                  <div className="w-1/2 flex justify-end item-center mt-4">
                    <select
                      value={label}
                      onChange={(e) => setLabel(e.currentTarget.value)}
                      className="mr-2 rounded-xl w-full"
                    >
                      <option value="">Durum Seçiniz</option>

                      <option value="waitingProducing">Üretim Bekliyor</option>
                      <option value="producing">Üretiliyor</option>
                      {/* <option value="waitingLabel">Etiket Bekliyor</option>
                      <option value="ready">Hazır</option> */}
                      {/* <option value="shipped">Kargoya Verildi</option>
                      <option value="delivered">Teslim Edildi</option>
                      <option value="refunded">İade Edildi</option> */}
                    </select>
                  </div>
                </div>

                <div className="grid justify-items-end mt-8">
                  <button
                    onClick={() => handleSubmitStatusChange()}
                    className="bg-[#1D4ED8] hover:bg-blue-700 text-white py-2 px-4 rounded-lg"
                  >
                    Onayla
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StatusChangeModal;
