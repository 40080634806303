import React, { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link1 } from 'iconsax-react';
import marketplaceService from '../../services/marketplaceService';

import alertNotification from '../../utils/alertNotification';
import MatchAllProductsModal from './MatchAllProductsModal';
import httpClient from '../../utils/httpClient';

interface Props {
  productId: string;
  products: any;
  refetch: () => void;
}
const ProductSummaryCard: React.FC<Props> = ({
  productId,
  products,
  refetch
}) => {
  const [loading, setLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<any>();
  const { isLoading, error, data } = useQuery<any, Error>(
    ['myMarketplaceProductDetailsStats', productId],
    {
      queryFn: () =>
        marketplaceService.getMyProductDetailsStats(Number(productId)),
      staleTime: Infinity,
    }
  );


  const handleMatch = async () => {
    setLoading(true);
    const tempMatch = products.map((item: any) => {
      return {
        sellerProductId: Number(item.id),
        supplierProductVariantId: Number(selectedProduct.id),
        sellerOrderItemId: Number(item.seller_order_item_id),
        isRematch: item.supplier_product_variant_id ? true : false,
      };
    });
    try {
      await httpClient.post(`/api/v1/marketplace/seller/product-match`, {
        matchingData: tempMatch,
      });
      refetch();
      alertNotification('success', 'Sipariş eşleştirme başarılı');
      setIsModalOpen(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="rounded-xl bg-[#F9FAFB] flex flex-col  lg:justify-between lg:flex-row space-y-2 lg:space-x-2 p-2 xl:p-6 w-full shadow-md items-center">
      <div className="flex items-center space-x-4 w-full">
        <img
          src={data?.data[0].images[0]}
          alt="product"
          className="w-[100px] aspect-square rounded-xl"
        />
        {isLoading ? (
          <div className="w-full h-6 bg-lightGray animate-pulse rounded-lg mb-2"></div>
        ) : (
          <p className="text-[#1F2937] font-bold text-sm xl:text-lg">
            {data?.data[0].title}
          </p>
        )}
      </div>
      <div className="flex w-full items-center space-x-2">
        <div className="text-[#1F2937] w-full full text-sm lg:text-base">
          {isLoading ? (
            <div className="w-full h-6 bg-lightGray animate-pulse rounded-lg mb-2"></div>
          ) : (
            <p className="font-thin">
              <span className="font-medium">SKU:</span> {data?.data[0].sku}
            </p>
          )}
          {isLoading ? (
            <div className="w-1/3 h-6 bg-lightGray animate-pulse rounded-lg mb-2"></div>
          ) : (
            <p className="font-thin">
              <span className="font-medium">Varyasyon Sayısı:</span>{' '}
              {data?.data[0].variation_count}
            </p>
          )}
          {isLoading ? (
            <div className="w-1/3 h-6 bg-lightGray animate-pulse rounded-lg mb-2"></div>
          ) : (
            <p className="font-thin">
              <span className="font-medium">Toplam Satış Sayısı:</span>{' '}
              {data?.data[0].total_sales_count}
            </p>
          )}
        </div>
        {isLoading || loading ? (
          <div className="w-1/3 h-6 bg-lightGray animate-pulse rounded-lg"></div>
        ) : (
          <div className="w-full">
            <button
              className="bg-[#4F46E5] rounded-lg flex mx-auto justify-center items-center w-full lg:w-auto space-x-2 py-2 px-4"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <Link1 size={24} color="#fff" />
              <p className="text-white font-bold text-xs xl:text-base">
                Ürün Eşleştir
              </p>
            </button>
          </div>
        )}
      </div>
      <MatchAllProductsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleMatch={handleMatch}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />
    </div>
  );
};

export default ProductSummaryCard;
