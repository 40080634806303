import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import alertNotification from '../../../utils/alertNotification';
import { ToastContainer } from 'react-toastify';
import { useNumberVerification } from '../../../context/NumberVerificationContext';
import { useAuth } from '../../../context/AuthContext';
import { Information } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';
import { useLanguage } from '../../../context/LanguageContext';
interface Currency {
  code: string;
  currency: string;
}

interface LinkProps {
  placeholder: string;
  CURRENCIES: Currency[];
  setShopCurrency: React.Dispatch<React.SetStateAction<string>>;
  setProductLink: React.Dispatch<React.SetStateAction<string>>;
  shopCurrency: string | undefined;
}

const LinkForm: React.FC<LinkProps> = ({
  placeholder,
  CURRENCIES,
  setShopCurrency,
  setProductLink,
  shopCurrency,
}) => {
  const { session } = useAuth();
  const {
    setOpenSurvey,
    setNumVerifyModal,
    setSixDigitVerifyModal,
    isCodeSend,
  } = useNumberVerification();
  const { t, language } = useLanguage();

  const onSubmit = (
    values: { shopCurrency: string; productLink: string },
    formikHelpers: FormikHelpers<{ shopCurrency: string; productLink: string }>
  ) => {
    const { setSubmitting } = formikHelpers;
    if (
      (session?.hasPackage || session?.lifeTimeCourse) &&
      !session?.isInitialSurveyDone
    ) {
      setSubmitting(false);
      setOpenSurvey?.(true);
    } else if (
      !session?.hasPackage &&
      !session?.lifeTimeCourse &&
      !session?.isGsmVerified
    ) {
      setSubmitting(false);
      if (!isCodeSend) {
        setNumVerifyModal?.(true);
      } else if (isCodeSend) {
        setSixDigitVerifyModal?.(true);
      }
    } else {
      if (!values.productLink) {
        alertNotification('error', 'Lütfen link bölümünü doldurunuz');
      }
      if (values.productLink.startsWith('https://www.etsy.com')) {
        setProductLink(values.productLink);
      } else {
        alertNotification('error', 'Lütfen geçerli bir link giriniz');
      }
      setShopCurrency(values.shopCurrency);
    }
  };

  return (
    <div className="flex w-full py-3 bg-white mt-4">
      <ToastContainer />
      <Formik
        initialValues={{
          shopCurrency: 'USD',
          productLink: '',
        }}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, errors, values, setFieldValue }) => (
          <form
            className="grid grid-cols-12 w-full lg:gap-4"
            onSubmit={handleSubmit}
          >
            <div className="col-span-12 mb-2 lg:mb-0 lg:col-span-6 xl:col-span-5 px- min-[1335px]:pr- min-[1335px]:pl-">
              <div className="flex items-center">
                <div className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1 ">
                  <div className="flex justify-between">
                    <label htmlFor="shopCurrency">
                      {language === 'tr'
                        ? 'Mağaza Para Birimi'
                        : 'Store Currency'}{' '}
                    </label>
                    <Information
                      size={20}
                      className="cursor-pointer"
                      id="shopCurrencyToolTip"
                    />
                    <Tooltip anchorSelect="#shopCurrencyToolTip">
                      {language === 'tr' ? (
                        <>
                          Ürünleri satmak için kullanılan para birimidir. Gelir
                          ve <br />
                          Maliyet bölümündeki girdiler bu para biriminde
                          yapılır.
                        </>
                      ) : (
                        <>
                          It is the currency used to sell products. Income and{' '}
                          <br />
                          Entries in the cost section are made in this currency.
                        </>
                      )}
                    </Tooltip>
                  </div>
                </div>

                <select
                  id="shopCurrency"
                  value={values.shopCurrency}
                  onChange={(e) => {
                    handleChange(e);
                    setShopCurrency(e.target.value);
                  }}
                  name="shopCurrency"
                  defaultValue={'USD'}
                  className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                >
                  {CURRENCIES.map((cur) => {
                    return <option value={cur.code}>{cur.currency}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="col-span-12  lg:col-span-6 xl:col-span-7 relative">
              <div className="relative">
                <input
                  //   ref={inputRef}
                  id="productLink"
                  value={values.productLink}
                  onChange={handleChange}
                  className="block w-full p-[0.58rem] text-xs xl:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50"
                  placeholder={placeholder.substring(0, 70) + '...'}
                />
                <button
                  disabled={!values.productLink}
                  type="submit"
                  className="text-white right-[1px]  bottom-0 absolute bg-headerPrimary rounded-r-md font-medium text-xs xl:text-sm px-4 p-[0.62rem] md:py-2.5"
                >
                  {t?.('common.buttons.search')}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LinkForm;
