let API_URL: string;
let PAYMENT_URL: string;
let IMAGE_URL: string;
let REDIRECT_URL: string;
let STRIPE_KEY: string;

//main pushu
if (process.env.REACT_APP_ENV === 'production') {
  API_URL = process.env.REACT_APP_BASE_URL_PROD!;
  PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL_PROD!;
  STRIPE_KEY = process.env.REACT_APP_STRIPE_API_KEY_PROD!;
  IMAGE_URL = process.env.REACT_APP_IMAGE_URL_PROD!;
  REDIRECT_URL = process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_PROD!;
} else if (process.env.REACT_APP_ENV === 'test') {
  API_URL = process.env.REACT_APP_BASE_URL_TEST!;
  PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL_TEST!;
  IMAGE_URL = process.env.REACT_APP_IMAGE_URL_TEST!;
  STRIPE_KEY = process.env.REACT_APP_STRIPE_API_KEY_TEST!;
  REDIRECT_URL = process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_TEST!;
} else if (process.env.REACT_APP_ENV === 'development') {
  API_URL = process.env.REACT_APP_BASE_URL_DEV!;
  PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL_DEV!;
  IMAGE_URL = process.env.REACT_APP_IMAGE_URL_TEST!;
  STRIPE_KEY = process.env.REACT_APP_STRIPE_API_KEY_TEST!;
  REDIRECT_URL = process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_TEST!;
}

export default API_URL!;

export { PAYMENT_URL, IMAGE_URL, REDIRECT_URL, STRIPE_KEY };
