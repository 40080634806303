import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import * as pdfjsLib from 'pdfjs-dist';
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
// pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url
// ).toString();
interface Props {
  setImage: React.Dispatch<any>;
  setOriginalFile: React.Dispatch<any>;
}

const DesignDropZone: React.FC<Props> = ({ setImage, setOriginalFile }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      // Orijinal PDF dosyasını kaydet

      if (file.type === 'application/pdf') {
        setOriginalFile(file);
        const reader = new FileReader();
        reader.onload = async (e) => {
          const pdfData = e.target?.result;
          if (pdfData) {
            const loadingTask = pdfjsLib.getDocument({ data: pdfData });
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(1);
            const viewport = page.getViewport({ scale: 1.5 });

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const renderContext = {
              canvasContext: context!,
              viewport: viewport,
            };
            await page.render(renderContext).promise;

            // setImage({
            //   preview: canvas.toDataURL('image/png'),
            // });

            setImage(
              Object.assign(file, { preview: canvas.toDataURL('image/png') })
            );
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setImage(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setImage, setOriginalFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
    onDrop,
  });
  return (
    <div
      {...getRootProps()}
      className="p-6 border-dashed border-[#2563EB] border-2"
    >
      <input {...getInputProps()} />
      <p className="text-center">
        Yüklemek istediğiniz tasarımı sürükleyip bırakın veya seçmek için
        tıklayın
      </p>
    </div>
  );
};

export default DesignDropZone;
