import React from 'react';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import marketplaceService from '../../services/marketplaceService';
import alertNotification from '../../utils/alertNotification';
import { useNavigate } from 'react-router-dom';

export interface IShops {
  status: string;
  data: {
    id: number;
    name: string;
    currency: string;
  }[];
}
interface Props {
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  setShopId: React.Dispatch<React.SetStateAction<string>>;
  setSku: React.Dispatch<React.SetStateAction<string>>;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const MyProductFilter: React.FC<Props> = ({
  setKeyword,
  setShopId,
  setSku,
  setQueryString,
  setPage,
}) => {
  const navigate = useNavigate();

  const { error, data } = useQuery<IShops, Error>(['myShops'], {
    queryFn: () => marketplaceService.getMyShops(),
    staleTime: Infinity,
  });

  if (error) {
    alertNotification('error', error.message);
  }

  const submitForm = (values: any) => {
    console.log(values);
    setPage(1);
    setShopId(values.storeId);
    setKeyword(values.keyword);
    setSku(values.sku);
    setQueryString(
      `keyword=${values.keyword}&storeId=${values.storeId}&sku=${values.sku}`
    );
    navigate(
      `?page=1&keyword=${values.keyword}&storeId=${values.storeId}&sku=${values.sku}`
    );
  };

  const formik = useFormik({
    initialValues: {
      storeId: null,
      keyword: '',
      sku: '',
    },
    onSubmit: submitForm,
  });

  return (
    <div className="w-full mt-8">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col space-y-2 space-x-0 sm:space-y-0 sm:space-x-8 sm:flex-row justify-between items-center w-full"
      >
        <div className="flex w-full space-x-8">
          <select
            name="storeId"
            placeholder="Lütfen mağaza seçiniz"
            onChange={formik.handleChange}
            className="border-[#6B7280] rounded-md w-1/2"
          >
            <option value="">Hepsi</option>
            {data?.data.map((item: any) => {
              return <option value={Number(item.id)}>{item.name}</option>;
            })}
          </select>

          <input
            className="border-[#6B7280] rounded-md placeholder:text-xs px-8 w-1/2 "
            type="text"
            onChange={formik.handleChange}
            name="keyword"
            placeholder="Ürün başlığı ile ürün arayın"
          />
        </div>
        <div className="flex w-full space-x-8">
          <input
            className="border-[#6B7280] rounded-md placeholder:text-xs px-8 w-1/2 "
            type="text"
            name="sku"
            onChange={formik.handleChange}
            placeholder="Sku ile ürün arayın"
          />
          <button className="bg-[#1D4ED8] rounded-lg px-4 py-2 text-white w-1/2">
            Filtrele
          </button>
        </div>
      </form>
    </div>
  );
};

export default MyProductFilter;
