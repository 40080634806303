import React from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface RejectReasonModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reason: any;
}

const RejectReasonModal: React.FC<RejectReasonModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  reason,

  // refetchData,
}) => {
  // const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl 3xl:max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
                >
                  Red Sebebi
                </Dialog.Title>
                {/* <div className="mt-2 flex px-8">
                  <div className="flex flex-col">
                    <div className="flex flex-row ">
                      <img
                        src={selectedOrder?.seller_order_items[0].seller_product_variant.images[0]}
                        alt="Order item"
                        className="w-20 h-20 3xl:w-20 3xl:h-20"
                      />
                      <p className="px-4 text-md">
                        {selectedOrder?.seller_order_items[0].seller_product_variant.name}
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="py-8 px-8">
                  <p>{reason}</p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RejectReasonModal;
