import React from 'react';

import { useLanguage } from '../../../context/LanguageContext';
import memberService from '../../../services/memberService';
import { classNames } from '../../../utils/conditionalClasses';

interface NewBannerProps {
  bgUrl: string;
  toolImageUrl?: string;
  toolWithoutImg?: string;
  bannerOnClick?: Function;
}

const NewBanner: React.FC<NewBannerProps> = ({
  bgUrl,
  toolImageUrl,
  toolWithoutImg,
  bannerOnClick,
}) => {
  const { t, language } = useLanguage();
  const discordLogin = async () => {
    // let response = await memberService.discordRefresh();
    //redirect to discord
    window.location.href = 'https://chat.whatsapp.com/COb9QTT6wi10fEuYoNjbS3';
    //window.location.reload();
  };

  let toolDescription;
  if (toolImageUrl === 'etsy-top-products') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          <span className="text-[#F45900]">Etsy </span>{' '}
          {t?.('etsy-top-products.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'etsy-hot-products') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          <span className="text-[#F45900]">Etsy </span>{' '}
          {t?.('etsy-hot-products.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'etsy-top-sellers') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          <span className="text-[#F45900]">Etsy </span>{' '}
          {t?.('etsy-top-sellers.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'etsy-hot-sellers') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          <span className="text-[#F45900]">Etsy </span>{' '}
          {t?.('etsy-hot-sellers.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'danismanlik') {
    toolDescription =
      language === 'tr' ? (
        <div className="   uppercase font-bold text-center px-2 py-2 text-xl md:text-xl  md:leading-[30px]   md:px-8 lg:text-xl xl:px-8 xl:text-2xl xl:leading-[40px] ">
          <span className="text-headerPrimary">
            3 Ay içinde <span className="text-rexRed">Globalde E-Ticaret </span>
            Yapmanızı Sağlıyoruz
          </span>
          <p className="mt-4 text-xs md:mt-2  text-center md:text-sm font-semibold normal-case">
            Ürünüm var, dünyaya açmak istiyorum veya yatırım yapacak bütçem var
            diyorsanız ürünlerinizi ve bütçenizi analiz ettikten sonra hızlı bir
            şekilde <span className="text-[#FF9900] uppercase">AMAZON</span> ya
            da <span className="text-[#F45900] uppercase">ETSY</span>'de
            mağazanızı kurup satış yapacak seviyeye getiriyoruz
          </p>
        </div>
      ) : (
        <div className="   uppercase font-bold text-center px-2 py-2 text-xl md:text-xl  md:leading-[30px]   md:px-8 lg:text-xl xl:px-8 xl:text-2xl xl:leading-[40px] ">
          <span className="text-headerPrimary">
            We Enable You to Do
            <span className="text-rexRed"> E-Commerce Globally </span>
            in 3 Months
          </span>
          <p className="mt-4 text-xs md:mt-2 text-center md:text-sm font-semibold normal-case">
            If you have a product you want to sell globally, or have a budget
            for investment, we analyze your products and budget and then quickly
            set up your store on{' '}
            <span className="text-[#FF9900] uppercase">AMAZON</span> or{' '}
            <span className="text-[#F45900] uppercase">ETSY</span>, bringing it
            to a sales-ready level
          </p>
        </div>
      );
  } else if (toolImageUrl === 'etsy-kar-hesaplama') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-2xl md:text-3xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-4xl xl:leading-[50px] ">
        <p className="text-headerPrimary">
          {language === 'tr' ? (
            <>
              <span className="text-[#F45900]">Etsy</span>'de Kar Marjınızı
              Hesaplayın <span className="text-rexRed">ZARAR</span> Etmeyin!
            </>
          ) : (
            // <>
            //   CALCULATE YOUR PROFIT MARGIN ON{' '}
            //   <span className="text-[#F45900]">ETSY </span>DON'T
            //   <span className="text-rexRed"> LOSS!</span>
            // </>
            <>
              CALCULATE YOUR PROFIT MARGIN ON{' '}
              <span className="text-[#F45900]">ETSY </span>
            </>
          )}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'trendyol-kar-hesaplama') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-2xl md:text-3xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-4xl xl:leading-[50px] ">
        <p className="text-headerPrimary">
          <span className="text-[#F27B1A]">Trendyol</span>'da Kar Marjınızı
          Hesaplayın <span className="text-rexRed">ZARAR</span> Etmeyin!
        </p>
      </div>
    );
  } else if (toolImageUrl === 'aliexpress-kar-hesaplama') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-2xl md:text-3xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-4xl xl:leading-[50px] ">
        {language === 'tr' ? (
          <p className="text-headerPrimary">
            <span className="text-[#F27B1A]">Alıexpress</span>'te Kar Marjınızı
            Hesaplayın <span className="text-rexRed">ZARAR</span> Etmeyin!
          </p>
        ) : (
          <p className="text-headerPrimary">
            CALCULATE YOUR PROFIT MARGIN ON
            <span className="text-[#F27B1A]"> ALIEXPRESS</span>
          </p>
        )}
      </div>
    );
  } else if (toolImageUrl === 'rexven-exclusive') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-2xl md:text-2xl  md:leading-[40px] md:text-center  md:px-8 xl:px-20 xl:text-3xl xl:leading-[45px] ">
        {language === 'tr' ? (
          <p className="text-headerPrimary">
            Rexven <span className="text-rexRed">Exclusıve </span> Üyelerimize
            Sunduğumuz Ürünler
          </p>
        ) : (
          <p className="text-headerPrimary">
            PRODUCTS WE OFFER TO OUR REXVEN{' '}
            <span className="text-rexRed">EXCLUSIVE </span> MEMBERS
          </p>
        )}
      </div>
    );
  } else if (toolImageUrl === 'gpt-yorum-analizi') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          {t?.('amazon-review-analysis.bannerTitle1')}
          <span className="text-rexRed">
            {' '}
            {t?.('amazon-review-analysis.bannerTitle2')}{' '}
          </span>{' '}
          {t?.('amazon-review-analysis.bannerTitle3')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'etsy-store-analyze') {
    toolDescription = (
      <div className="uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        {language === 'tr' ? (
          <p className="text-headerPrimary">
            <span className="text-[#F45900]"> Etsy </span> RAKİP <br /> ANALİZİ
          </p>
        ) : (
          <p className="text-headerPrimary">
            <span className="text-[#F45900]"> Etsy </span> COMPETITOR <br />
            ANALYSIS
          </p>
        )}
      </div>
    );
  } else if (toolImageUrl === 'thanks-page') {
    toolDescription = (
      <div className="text-center w-full mt-[50px]  px-[2px]  ">
        <div className="flex justify-center">
          <h1 className="font-black uppercase text-headerPrimary text-2xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px]">
            Teşekkürler!
          </h1>
        </div>
        <p className="my-4 px-10 text-center text-md lg:text-lg font-semibold ">
          Üyelik kaydınız başarıyla gerçekleşti.{' '}
          <span className="text-rexRed"> Rexven </span>
          ekosistemine hoşgeldiniz! Ekosistemde görüşmek üzere...
        </p>
        <div className="flex justify-center">
          <button
            onClick={discordLogin}
            className="bg-headerPrimary text-white px-4 py-2 rounded-lg shadow-md"
          >
            WhatsApp Grubuna Katıl
          </button>
        </div>
      </div>
    );
  } else if (toolImageUrl === 'pricing-page') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">Ekosistem Paketleri</p>
      </div>
    );
  } else if (toolImageUrl === 'service-providers') {
    toolDescription = (
      <div className="font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          {t?.('service-providers.page.title')}
        </p>
      </div>
    );
  } else if (toolWithoutImg === 'Mağazamı Entegre Et') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-2xl md:text-3xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-4xl xl:leading-[50px] ">
        <p className="text-headerPrimary text-center">
          ARACI KULLANMAK İÇİN{' '}
          <span className="text-[#F27B1A]">TRENDYOL MAĞAZANIZI</span> ENTEGRE
          EDİNİZ
        </p>
      </div>
    );
  } else if (toolImageUrl === 'amazon-arbitraj') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          <span className="text-[#FF9900]"> Amazon </span>{' '}
          {t?.('amazon-online-arbitrage.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'amazon-product-finder') {
    toolDescription = (
      <div className="  font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          <span className="text-[#FF9900]"> AMAZON </span>{' '}
          {t?.('amazon-product-finder.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'amazonkeyword') {
    toolDescription = (
      <div className="  font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          <span className="text-[#FF9900]"> AMAZON </span> KELİME ARAŞTIRMA
        </p>
      </div>
    );
  } else if (toolImageUrl === 'aliExpressTopProducts') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl min-[1450px]:text-6xl  ">
        <p className="text-headerPrimary" style={{ lineHeight: '65px' }}>
          <span className="text-[#FF9900]">
            {' '}
            <span className="text-[#F7981C]">ALI</span>
            <span className="text-[#E53225]">express</span>
            <span className="text-[#1F254B]">
              {' '}
              {language === 'tr'
                ? 'CIS (Rusya) Çok Satan Ürünler'
                : 'CIS (RUSSIA) BEST SELLING PRODUCTS'}
            </span>
          </span>
        </p>
      </div>
    );
  } else if (toolImageUrl === 'aliexpress-top-keywords') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl min-[1450px]:text-6xl  ">
        <p className="text-headerPrimary" style={{ lineHeight: '65px' }}>
          <span className="text-[#FF9900]">
            {' '}
            <span className="text-[#F7981C]">AlI</span>
            <span className="text-[#E53225]">express</span>
            <span className="text-[#1F254B]">
              {' '}
              {language === 'tr'
                ? 'CIS (Rusya) Çok Aranan Kelimeler'
                : 'CIS (RUSSIA) MOST SEARCHED KEYWORDS'}
            </span>
          </span>
        </p>
      </div>
    );
  } else if (toolImageUrl === 'shipentegra-create-post') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-2xl md:text-3xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-4xl xl:leading-[50px] ">
        {language === 'tr' ? (
          <p className="text-headerPrimary">GÖNDERİ OLUŞTUR</p>
        ) : (
          <p className="text-headerPrimary">CREATE SHIPMENT</p>
        )}
      </div>
    );
  } else if (toolImageUrl === 'shipentegra-orders') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-2xl md:text-3xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-4xl xl:leading-[50px] ">
        {language === 'tr' ? (
          <p className="text-headerPrimary">SİPARİŞLERİM</p>
        ) : (
          <p className="text-headerPrimary">MY ORDERS</p>
        )}
      </div>
    );
  } else if (toolImageUrl === 'shipping-calculator') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          {t?.('shipEntegra.shippingCalculator.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'shipments') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          {t?.('shipEntegra.shipments.bannerTitle')}
        </p>
      </div>
    );
  } else if (toolImageUrl === 'amazon-forecast') {
    toolDescription = (
      <div className=" uppercase font-black text-center w-full  px-[2px] text-4xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px] ">
        <p className="text-headerPrimary">
          {language === 'tr' ? (
            <p className="text-headerPrimary">
              <span className="text-[#F7981C]"> AMAZON </span> 2 YILLIK <br />{' '}
              FORECAST ARACI
            </p>
          ) : (
            <p className="text-headerPrimary">
              <span className="text-[#F7981C]"> AMAZON </span> 2 YEARS <br />
              FORECAST
            </p>
          )}
        </p>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        toolImageUrl === 'thanks-page' ? 'h-[340px]' : 'h-[240px]',
        `w-full rounded-xl mb-8`
      )}
      style={{
        backgroundImage: `url(${bgUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'full',
      }}
    >
      {toolImageUrl && (
        <div className="grid gap-4 grid-cols-12 p-2 w-full">
          <div className="col-span-12 md:col-span-8 w-full ">
            <div className=" h-[240px] flex justify-between items-center my-auto">
              {toolDescription}
            </div>
          </div>

          <div className="hidden md:block md:col-span-4 ">
            <div className="w-full h-[224px] aspect-w-1 rounded-lg m-auto flex items-center justify-center">
              <img
                src={`/images/${toolImageUrl}.webp`}
                alt="product_image"
                className="h-full w-full object-contain object-center"
              />
            </div>
          </div>
        </div>
      )}
      {toolWithoutImg && (
        <div className="grid gap-2 grid-cols-12 p-2 w-full h-[100%]">
          <div className="col-span-12 lg:col-span-8 w-full ">
            <div className=" h-[100%] flex justify-between items-center my-auto">
              {toolDescription}
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4 ">
            <div className="w-full h-[50px] lg:h-[100%] aspect-w-1 rounded-lg m-auto flex items-center justify-center">
              <button
                onClick={() => bannerOnClick?.()}
                className="bg-headerPrimary text-white border-2 rounded-lg py-2 px-10 md:px-6 lg:px-8 xl:px-10 lg:py-3 font-bold my-auto"
              >
                {toolWithoutImg}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewBanner;
