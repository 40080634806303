import React, { useState } from 'react';
import DemoFilter from '../components/marketplaceDemo/DemoFilter';
import DemoStatusSection from '../components/marketplaceDemo/DemoStatusSection';
import DemoTable from '../components/marketplaceDemo/DemoTable';

const MarketplaceOrdersDemo = () => {
  const [status, setStatus] = useState('');
  return (
    <div>
      <DemoFilter />
      <div className="flex justify-between items-center flex-1 space-x-4 p-4">
        <button
          className={
            'text-[#4B5563] bg-[#D1D5DB] text-xs 2xl:text-sm px-4 py-2 rounded-lg '
          }
          disabled={true}
        >
          Ödeme Yap
        </button>
        {/* <div className="flex justify-center mb-4 items-center space-x-4"> */}
        <button className="bg-[#047857] text-white font-medium text-xs 2xl:text-sm py-2 rounded-full w-44 border-none">
          Cüzdan: ${Number(1258.32)}
        </button>
        {/* </div> */}
      </div>
      <DemoStatusSection setStatus={setStatus} status={status} />
      <DemoTable />
    </div>
  );
};

export default MarketplaceOrdersDemo;
