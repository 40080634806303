import React from 'react';
import { BagHappy, Money4, WalletAdd1, WalletMinus } from 'iconsax-react';
import { useQuery } from '@tanstack/react-query';
import marketplaceService from '../../services/marketplaceService';
import { classNames } from '../../utils/conditionalClasses';
import alertNotification from '../../utils/alertNotification';

export interface IShopStats {
  status: string;
  data: {
    totalRevenue: number;
    totalOrderCount: number;
    totalOrderItemCount: number;
    averageSalesPrice: number;
    averageOrderPrice: number;
  };
}
const ShopInfoCardWrapper = () => {
  const { isLoading, error, data } = useQuery<IShopStats, Error>(
    ['shopStats'],
    {
      queryFn: () => marketplaceService.getShopStats(),
      staleTime: Infinity,
    }
  );

  if (error) {
    alertNotification('error', error.message);
  }
  return (
    <div className="grid grid-cols-12 gap-4 xl:gap-8 w-full">
      <div
        className={classNames(
          isLoading ? 'animate-pulse' : 'animate-none',
          'col-span-6 xl:col-span-3 bg-[#F9FAFB] p-4 rounded-lg shadow-md'
        )}
      >
        <div className="flex justify-between items-center  text-[#374151]">
          <p className="text-medium text-base xl:text-lg">Toplam Ciro</p>
          <Money4 size="48" color="#60A5FA" />
        </div>
        <p className="font-bold text-2xl ">${data?.data.totalRevenue ? data?.data.totalRevenue.toFixed(0): 0}</p>
      </div>
      <div
        className={classNames(
          isLoading ? 'animate-pulse' : 'animate-none',
          'col-span-6 xl:col-span-3 bg-[#F9FAFB] p-4 rounded-lg shadow-md'
        )}
      >
        <div className="flex justify-between items-center  text-[#374151]">
          <p className="text-medium text-base xl:text-lg">Toplam Satış</p>
          <BagHappy size="48" color="#A78BFA" />
        </div>
        <p className="font-bold text-2xl">{data?.data.totalOrderCount}</p>
      </div>
      <div
        className={classNames(
          isLoading ? 'animate-pulse' : 'animate-none',
          'col-span-6 xl:col-span-3 bg-[#F9FAFB] p-4 rounded-lg shadow-md'
        )}
      >
        <div className="flex justify-between items-center  text-[#374151]">
          <p className="text-medium text-base xl:text-lg">Ort. Satış Fiyatı</p>
          <WalletAdd1 size="48" color="#34D399" />
        </div>
        <p className="font-bold text-2xl ">
          ${data?.data.averageSalesPrice ? data?.data.averageSalesPrice.toFixed(2): 0}
        </p>
      </div>
      <div
        className={classNames(
          isLoading ? 'animate-pulse' : 'animate-none',
          'col-span-6 xl:col-span-3 bg-[#F9FAFB] p-4 rounded-lg shadow-md'
        )}
      >
        <div className="flex justify-between items-center  text-[#374151]">
          <p className="text-medium text-base xl:text-lg">Ort. Alış Fiyatı</p>
          <WalletMinus size="48" color="#F87171" />
        </div>
        <p className="font-bold text-2xl ">
          ${data?.data.averageOrderPrice ? data?.data.averageOrderPrice.toFixed(2): 0}
        </p>
      </div>
    </div>
  );
};

export default ShopInfoCardWrapper;
