import React, { useState, useEffect, lazy, Suspense } from 'react';
// import ToolSearchBar from '../components/common/ToolSearchBar';
import ProfitProductInfo from '../components/tools/common/ProfitProductInfo';
import VideoTools from '../components/tools/common/VideoTools';
import SmallInfoCards from '../components/tools/common/SmallInfoCards';
import PieChart from '../components/tools/common/PieChart';
import TrendyolCalculateProfitForm from '../components/trendyolProfit/TrendyolCalculateProfitForm';
import TrendyolCalculateResultForm, {
  TrendyolCalculateResult,
} from '../components/trendyolProfit/TrendyolCalculateResultForm';
import alertNotification from '../utils/alertNotification';
import trendyolToolsService from '../services/trendyolToolsService';

import {
  WalletAdd1,
  Box1,
  PercentageSquare,
  ReceiptDisscount,
  Box2,
} from 'iconsax-react';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import SurveyModal from '../components/survey/SurveyModal';
import { useNumberVerification } from '../context/NumberVerificationContext';
import { useAuth } from '../context/AuthContext';
import BannerLoading from '../components/tools/common/BannerLoading';
import memberService from '../services/memberService';
const NewBanner = lazy(() => import('../components/tools/common/NewBanner'));

const chartOptions = {
  labels: ['Kar', 'Kesinti', 'Maliyet'], // Value Titles
  colors: ['#0284C7', '#F27B1A', '#DB2777'], // Value Colors
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '50px',
        labels: {
          show: true,
        },
      },
    },
  },
};

interface ProductInfo {
  productImg: string;
  productTitle: string;
  brand: string;
  sellerName: string;
  rating: number;
  sellingPrice: number;
  commissionRate: number;
}

const TrendyolProfitCalculator = () => {
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [productLink, setProductLink] = useState('');
  const [productInfo, setProductInfo] = useState<ProductInfo>();
  const [resultLoading, setResultLoading] = useState(false);

  const { session } = useAuth();
  const {
    setOpenSurvey,
    setNumVerifyModal,
    setSixDigitVerifyModal,
    isCodeSend,
  } = useNumberVerification();
  const [commissionRate, setCommissionRate] = useState<number>();

  const [infoCardsArr, setInfoCardsArr] = useState([
    {
      icon: WalletAdd1,
      title: 'Gelir',
      value: '-',
      iconColor: '#0F766E',
      titleColor: '#0D9488',
      valueColor: '#0F766E',
    },
    {
      icon: Box1,
      title: 'Maliyet',
      value: '-',
      iconColor: '#BE185D',
      titleColor: '#DB2777',
      valueColor: '#BE185D',
    },
    {
      icon: Box2,
      title: 'Kesinti',
      value: '-',
      iconColor: '#F27B1A',
      titleColor: '#C15D0B',
      valueColor: '#F27B1A',
    },
    {
      icon: ReceiptDisscount,
      title: 'Kar',
      value: '-',
      iconColor: '#0369A1',
      titleColor: '#0284C7',
      valueColor: '#0369A1',
    },

    {
      icon: PercentageSquare,
      title: 'Kar Marjı',
      value: '-',
      iconColor: '#4338CA',
      titleColor: '#6366F1',
      valueColor: '#4338CA',
    },
  ]);

  const [chartValues, setChartValues] = useState([
    //['Kar', 'Kesinti', 'Maliyet'],
    33.3, 33.3, 33.3,
  ]);

  const [calculateResult, setCalculateResult] =
    useState<TrendyolCalculateResult>();

  const onSubmit = async (event: any, values: any) => {
    // console.log('FormValues', values);

    const payload = {
      commissionRate: Number(values.commissionRate),
      vatRate: Number(values.vatRate),
      sellingPrice: Number(values.sellingPrice),
      isDiscountPercent: values.isPercent === 'Percent' ? true : false,
      discount: Number(values.discount),
      // discountFixed: values.isPercent === 'Fixed' ? Number(values.discount) : 0,
      shippingFee: Number(values.shippingIncome),
      productCost: Number(values.costOfPurchase),
      labourCost: Number(values.laborCost),
      packingCost: Number(values.packageCost),
      shippingCost: Number(values.shippingCost),
      advertisingCost: Number(values.advertisingCost),
    };

    if (
      (session?.hasPackage || session?.lifeTimeCourse) &&
      !session?.isInitialSurveyDone
    ) {
      event.preventDefault();
      setOpenSurvey?.(true);
    } else if (
      !session?.hasPackage &&
      !session?.lifeTimeCourse &&
      !session?.isGsmVerified
    ) {
      event.preventDefault();
      if (!isCodeSend) {
        setNumVerifyModal?.(true);
      } else if (isCodeSend) {
        setSixDigitVerifyModal?.(true);
      }
    } else {
      setResultLoading(true);

      trendyolToolsService
        .getProfitForTrendyol(payload)
        .then((res) => {
          setInfoCardsArr([
            {
              icon: WalletAdd1,
              title: 'Gelir',
              value: res.data.income + ' ₺',
              iconColor: '#0F766E',
              titleColor: '#0D9488',
              valueColor: '#0F766E',
            },
            {
              icon: Box1,
              title: 'Maliyet',
              value: res.data.totalCost + ' ₺',
              iconColor: '#BE185D',
              titleColor: '#DB2777',
              valueColor: '#BE185D',
            },
            {
              icon: Box2,
              title: 'Kesinti',
              value: res.data.grossingUp + ' ₺',
              iconColor: '#F27B1A',
              titleColor: '#C15D0B',
              valueColor: '#F27B1A',
            },
            {
              icon: ReceiptDisscount,
              title: 'Kar',
              value: res.data.profit + ' ₺',
              iconColor: '#0369A1',
              titleColor: '#0284C7',
              valueColor: '#0369A1',
            },

            {
              icon: PercentageSquare,
              title: 'Kar Marjı',
              value: res.data.profitMargin + ' %',
              iconColor: '#4338CA',
              titleColor: '#6366F1',
              valueColor: '#4338CA',
            },
          ]);

          setChartValues([
            res.data.profit,
            res.data.grossingUp,
            res.data.totalCost,
          ]);

          setCalculateResult(res.data);
        })
        .catch((err) => {
          // console.log(err);
          alertNotification('error', err.response.data.message);
        })
        .finally(() => {
          setResultLoading(false);
        });
    }
  };

  const getTrendyolProductInfo = async (productLink: string) => {
    trendyolToolsService
      .getProductWithUrl(productLink)
      .then((res) => {
        setProductInfo(res.data);
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      });
  };

  useEffect(() => {
    if (productLink && productLink.startsWith('https://www.trendyol.com')) {
      getTrendyolProductInfo(productLink);
    }
    // else {
    //    alertNotification('error', 'Lütfen linki doğru formatta doldurun');
    // }
  }, [productLink]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          `trendyol-profit-calculator`,
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <>
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />

      <div className="flex w-full flex-shrink-0 flex-col">
        {/* <Banner photoUrl="trendyol" /> */}
        <Suspense fallback={<BannerLoading />}>
          <NewBanner
            bgUrl={'/images/trendyol-kar-hesaplama-bg.webp'}
            toolImageUrl="trendyol-kar-hesaplama"
          />
        </Suspense>

        <div className="grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-12 lg:col-span-6 xl:col-span-5 bg-white">
            {productInfo && <ProfitProductInfo product={productInfo} />}

            <TrendyolCalculateProfitForm
              onSubmit={onSubmit}
              sellingPrice={productInfo ? productInfo.sellingPrice! : 0}
              commissionRate={commissionRate}
              setCommissionRate={setCommissionRate}
            />

            {calculateResult && (
              <TrendyolCalculateResultForm
                trendyolCalculateResult={calculateResult}
              />
            )}
          </div>
          <div className="col-span-12 lg:col-span-6 xl:col-span-7 ">
            {/* Video Content */}
            <VideoTools videoSrc="courses/shared/trendyol-kar-hesaplama.mp4" />

            {/* <img
              className="w-full rounded-xl"
              src="/images/trendyolCalcBanner.png"
              alt="trendyol-banner"
            /> */}

            {/* Small Info Cards */}
            <SmallInfoCards
              spanCount={1}
              infos={infoCardsArr}
              resultLoading={resultLoading}
            />

            {/* Apex Section */}
            <div className="mt-4 py-4  bg-white">
              <PieChart series={chartValues} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendyolProfitCalculator;
