import React from 'react';
import DatePicker from 'react-datepicker';
import { COUNTRIES } from '../marketplaceOrders/OrderFilterForm';

const shops = [
  { id: 1, name: 'Demo Mağaza 1' },
  { id: 2, name: 'Demo Mağaza 2' },
  { id: 3, name: 'Demo Mağaza 3' },
  { id: 4, name: 'Demo Mağaza 4' },
];
const DemoFilter = () => {
  return (
    <div className="bg-white rounded-xl p-4">
      <form className="grid grid-cols-8 gap-2">
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
          Mağazalar
        </label> */}
          <select
            name="shop"
            id="shop"
            className="border-[#6B7280] rounded-md text-sm w-full"
          >
            <option value="">Tüm Mağazalar</option>
            {shops?.map((shop) => (
              <option key={shop.id} value={shop.id}>
                {shop.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
          Ülke
        </label> */}
          <select
            name="country"
            id="country"
            className="border-[#6B7280] rounded-md text-sm w-full"
          >
            <option value="">Tüm Ülkeler</option>
            {COUNTRIES.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
          Başlangıç
        </label> */}
          <DatePicker
            className="border-[#6B7280] rounded-md text-sm pr-0 w-full"
            onChange={(date) => {
              return;
            }}
            maxDate={new Date()}
            placeholderText="Başlangıç tarihi"
            monthsShown={1}
          />
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
          Bitiş
        </label> */}
          <DatePicker
            className="border-[#6B7280] rounded-md text-sm pr-0 w-full"
            placeholderText="Bitiş tarihi"
            onChange={(date) => {
              return;
            }}
            // startDate={new Date()}
            maxDate={new Date()}
            monthsShown={1}
          />
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1">
          {/* <label className="block text-headerPrimary font-medium mb-2">
          Hediye?
        </label> */}
          <div className="flex items-center space-x-2">
            <select
              name="isGift"
              id="isGift"
              className="border-[#6B7280] rounded-md text-sm w-full"
            >
              <option value="">Hediye mi?</option>
              <option value={'yes'}>Evet</option>
              <option value={'no'}>Hayır</option>
            </select>
          </div>
        </div>
        <div className="col-span-4 xl:col-span-1 flex items-end">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              name="orderId"
              placeholder="Sipariş No"
              id="orderId"
              className="rounded-lg border-[#6B7280] text-sm w-full"
            />
          </div>
        </div>
        <div className="col-span-4 md:col-span-2 xl:col-span-1 flex items-end">
          <input
            type="text"
            name="customer"
            placeholder="Müşteri ismi"
            id="customer"
            className="rounded-lg border-[#6B7280] text-sm w-full"
          />
        </div>
        <div className="col-span-8 md:col-span-2 xl:col-span-1 flex items-end">
          <button
            disabled
            className="bg-[#1D4ED8] text-white px-4 py-2 rounded-lg w-full"
          >
            Filtrele
          </button>
        </div>
      </form>
    </div>
  );
};

export default DemoFilter;
