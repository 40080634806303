import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import StripeCheckoutForm from '../payment/StripeCheckoutForm';

interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  balance: number;
}

const StripeModal: React.FC<Props> = ({
  setIsModalOpen,
  isModalOpen,
  balance,
}) => {
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto mt-20">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white py-8 px-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-start leading-6"
                >
                  <div className="border-b-2 border-lightGray">
                    <p className="text-[24px] font-bold text-headerPrimary mb-2">
                      Cüzdan:{' '}
                    </p>
                    <p className="text-4xl font-bold text-[#059669]">
                      ${Number(balance).toFixed(2)}
                    </p>
                  </div>
                  <div className="sm:min-h-[73vh]">
                    <StripeCheckoutForm country="en" platform="marketplace" />
                  </div>
                </Dialog.Title>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StripeModal;
