import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  setImage: React.Dispatch<React.SetStateAction<any>>;
}

const DropImageResend: React.FC<Props> = ({ setImage }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setImage(
      Object.assign(acceptedFiles, {
        preview: acceptedFiles.map((item) => URL.createObjectURL(item)),
      })
    );
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop,
  });
  useEffect(() => {}, []);

  return (
    <div
      {...getRootProps()}
      className="w-1/2 border border-[#6B7280] flex justify-center items-center rounded-xl cursor-pointer"
    >
      <input {...getInputProps()} />
      <div className="w-full flex justify-center items-center">
        <PlusIcon className="w-24 h-24" color="#6B7280" />
        <p className="px-2 py-4 text-xs text-cardBorder">
          Bu alana tıklayarak veya sürükle-bırak şeklinde görselinizi
          yükleyebilirsiniz
        </p>
      </div>
    </div>
  );
};

export default DropImageResend;
