import React, { Suspense, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import BannerLoading from '../components/tools/common/BannerLoading';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';
import { triggerPurchaseEvent } from '../utils/gtagManager';

const ThanksPage = () => {
  const { session } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [discount, setDiscount] = useState<number>(0);
  const searchParams = new URLSearchParams(location.search);
  const coupon = searchParams.get('coupon');
  const value = parseFloat(searchParams.get('basePrice') || '');
  const discountedPrice = parseFloat(searchParams.get('discountedPrice') || '');
  const itemId = searchParams.get('itemId');
  const itemName = searchParams.get('itemName');
  const currentTimeInMilliseconds = Date.now();
  const transactionId = `${currentTimeInMilliseconds}-${session?.id}`;

  const returnBtn = async () => {
    navigate('/marketplace-orders');
  };
  useEffect(() => {
    if (!isNaN(value) && !isNaN(discountedPrice) && value !== 0) {
      const calculatedDiscount = (value - discountedPrice) / value;
      setDiscount(calculatedDiscount * 100);
    } else {
      setDiscount(0);
    }
  }, [value, discountedPrice]);
  useEffect(() => {
    triggerPurchaseEvent(
      'purchase',
      coupon,
      itemId,
      itemName,
      discount,
      value,
      transactionId
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Suspense fallback={<BannerLoading />}>
        {searchParams.get('itemName')?.toLowerCase().includes('marketplace') ? (
          <div
            className={
              'w-full rounded-xl mb-8 h-[340px] flex justify-center flex-col'
            }
            style={{
              backgroundImage: `url('/images/rexven-exclusive-bg.webp')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'full',
            }}
          >
            <div className="text-center w-full  px-[2px]  ">
              <div className="flex justify-center">
                <h1 className="font-black uppercase text-headerPrimary text-2xl md:text-4xl  md:leading-[50px] md:text-left  md:px-8 xl:px-20 xl:text-5xl xl:leading-[60px]">
                  Teşekkürler!
                </h1>
              </div>
              <p className="my-4 px-10 text-center text-md lg:text-lg font-semibold ">
                Bakiyeniz yüklenmiştir!
              </p>
            </div>
            <div className="flex justify-center items-center w-full ">
              <button
                onClick={returnBtn}
                className="bg-headerPrimary text-white px-4 py-2 rounded-lg shadow-md"
              >
                Siparişlere Dön
              </button>
            </div>
          </div>
        ) : (
          <NewBanner
            bgUrl={'/images/rexven-exclusive-bg.webp'}
            toolImageUrl="thanks-page"
          />
        )}
      </Suspense>
    </div>
  );
};

export default ThanksPage;
