import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import alertNotification from '../../utils/alertNotification';
import { EtsyOrderItem } from '../../pages/MarketplaceOrders';
import marketplaceService from '../../services/marketplaceService';

interface CancelOrderModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  selectedOrders: any[];
  setSelectedOrders: React.Dispatch<React.SetStateAction<EtsyOrderItem[]>>;
  getOrders: () => void;
}

const CancelOrderModal: React.FC<CancelOrderModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrders,
  setSelectedOrders,
  getOrders,
}) => {
  const handleCancel = async () => {
    try {
      const orderItemIds = selectedOrders.flatMap((order) =>
        order.seller_order_items
          ? order.seller_order_items.map((item: any) => Number(item.id))
          : [Number(order.id)]
      );

      await marketplaceService.cancelOrder(orderItemIds);

      alertNotification('success', 'Sipariş başarıyla iptal edildi');
      setIsModalOpen(false);
      setSelectedOrders([]);
      getOrders();
    } catch (error: any) {
      alertNotification(
        'error',
        error.response?.data?.message || 'Bir hata oluştu'
      );
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6">
          <div className="flex justify-between items-center mb-4">
            <Dialog.Title className="text-lg font-medium">
              Sipariş İptali
            </Dialog.Title>
            <button
              onClick={() => setIsModalOpen(false)}
              className="text-gray-400 hover:text-gray-500"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <p className="mb-6">
            Siparişiniz iptal edilecektir, onaylıyor musunuz?
          </p>

          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              Vazgeçtim
            </button>
            <button
              onClick={handleCancel}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
            >
              Onayla
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default CancelOrderModal;
