import React from 'react';
// import { XIcon } from '@heroicons/react/outline';
import { DocumentDownload } from 'iconsax-react';

interface LabelCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  labelCreationStatus: {
    orderId: number;
    status: string;
    message: string;
  }[];
}

const LabelCreationModal: React.FC<LabelCreationModalProps> = ({
  isOpen,
  onClose,
  labelCreationStatus,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* Modal panel */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full max-w-lg"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start justify-between">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Label Creation Status
              </h3>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={onClose}
              >
                {/* <XIcon className="h-6 w-6" /> */}
                <DocumentDownload className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Order ID
                    </th>
                 
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {labelCreationStatus.map((statusItem) => (
                    <tr
                      key={statusItem.orderId}
                      className={
                        statusItem.status === 'success'
                          ? 'bg-green-100'
                          : statusItem.status === 'error'
                          ? 'bg-red-100'
                          : 'bg-white'
                      }
                    >
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                        {statusItem.orderId}
                      </td>
                    
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                        {statusItem.message}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Optionally, add a loading spinner or progress indicator */}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabelCreationModal;