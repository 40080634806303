import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../utils/httpClient';
import { useDebounce } from 'use-debounce';
import { ToastContainer } from 'react-toastify';
import { SearchNormal } from 'iconsax-react';
import API_URL from '../../config';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleMatch: () => void;
  selectedProduct: any;
  setSelectedProduct: React.Dispatch<any>;
}
const MatchAllProductsModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  handleMatch,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [value] = useDebounce(searchTerm, 600);

  const { isLoading, error, data } = useQuery<any, Error>(
    ['matchingProductsAll', value],
    {
      queryFn: () => {
        return httpClient.get(
          `/api/v1/marketplace/all-products?keyword=${value}`
        );
      },
      staleTime: Infinity,
    }
  );

  const handleProductSelect = (product: any) => {
    setSelectedProduct(product);
  };

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => {
          setSelectedProduct(null);
          setIsModalOpen(false);
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <ToastContainer />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl h-[60vh] 3xl:max-w-5xl transform overflow-auto  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
                >
                  Sipariş Eşleştirme
                </Dialog.Title>
                <div className="mt-2 flex flex-col overflow-y-auto">
                  {
                    <div className="flex space-x-2">
                      <div className="flex-1">
                        <div className="mb-4 flex items-center">
                          <p className="text-rexRed font-medium">
                            Seçeceğiniz ürün aşağıdaki tüm varyasyonlarla
                            eşleştirilecektir.
                          </p>
                          <div className="flex justify-between items-center w-full border border-[#6B7280] rounded-full px-2">
                            <input
                              type="text"
                              placeholder="Ara..."
                              className="w-full  px-2 ml-2 border-none focus:ring-0 focus:outline-none focus:border-none"
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <SearchNormal size={24} color="#6B7280" />
                          </div>
                        </div>
                        {isLoading ? (
                          <div className="flex justify-center items-center h-60">
                            Ürünler yükleniyor...
                          </div>
                        ) : data?.data?.data.data.length > 0 ? (
                          <ul className="max-h-60 overflow-y-auto">
                            {data?.data?.data?.data?.map((product: any) =>
                              product.supplier_product_variants.map(
                                (item: any) => (
                                  <li
                                    key={item.id}
                                    className="flex text-sm items-center cursor-pointer p-2 hover:bg-gray-100 border border-[#6B7280] rounded-xl mb-2 shadow-md"
                                    onClick={() => handleProductSelect({...item,images:product.images[0]})}
                                  >
                                    {product?.images[0] && (
                                      <img
                                        src={
                                          API_URL +
                                          'images/marketplace/supplier-images/' +
                                          product?.images[0]
                                        }
                                        alt="Product"
                                        className="w-16 h-16 object-cover mr-2"
                                      />
                                    )}
                                    {item?.name}
                                  </li>
                                )
                              )
                            )}
                          </ul>
                        ) : (
                          <div className="bg-[#DBEAFE] rounded-xl p-3 text-[#2563EB]">
                            <p className="font-medium text-sm">
                              Ürün Eşleştirme
                            </p>
                            <p className="text-xs">
                              Ürünler SKU veya ismi ile aranabilir. Lütfen ürün
                              araması için en az 3 karakter giriniz
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  }
                  {selectedProduct && (
                    <div className="py-4">
                      <p className="text-headingPrimary">Seçilen Ürün</p>
                      <div className="flex w-full justify-start items-center border-t border-lightGray mt-4">
                        {
                          <img
                            src={
                              API_URL +
                              'images/marketplace/supplier-images/' +
                              selectedProduct?.images
                            }
                            alt="Product"
                            className="w-16 h-16 object-cover mr-2"
                          />
                        }
                        {selectedProduct?.name}
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    disabled={isLoading}
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-[#2563EB] px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleMatch}
                  >
                    {isLoading ? 'Ürünler Eşleştiriliyor...' : 'Eşleştir'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MatchAllProductsModal;
